import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import LoadingModal from '../modal/LoadingModal';
import Modaldanger from "../modal/danger";
import InputPrecioPanel from '../InputPrecioPanel';
import Labelmoney from "../../components/labelmoney";
import { AddDiasFecha, FechaLocalToUTC, FechaUTCToLocal, getArrayAsync, GetFechaActual, getSucursal, moneyToValue, shortIdToId, Sucursal, validaFecha, valueToMoney2 } from '../../services/functions';
import { formatoCelda, GenerateXlsx, SaveXlsx } from '../Excel/DataToXlsx';

export const ReporteCorteDia = () => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [modalError,setModalError]=useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [inputFecha,setInputFecha] =useState(GetFechaActual().fecha),
  [valores,setValores] = useState({});
  const inputIEfe = useRef(""), inputIBank = useRef(""),inputFEfe = useRef(""),inputFBank = useRef("");

  //const inputFecha = useRef("");
  const accounts =  Sucursal('cuentas');

  const openmodalLoading = (e) => { setmodalLoading({ isOpen: true }); };
  const closemodalLoading = (e) => { setmodalLoading({ isOpen: false }); };

  const handleError = (error) => {
    closemodalLoading();
    setModalError({isOpen:true,title:"Error de Red",message:error.message||error});
  }
  const closeModalError=()=>{ setModalError({isOpen:false}); }
  const openModalSuccess=(message,titulo)=>{
    setmodalSuccessConfig({
      isOpen: true,
      title: titulo||"Solicitud Completa",
      message: message,        
    });
  }
  const closeModalsuccess=(e)=> {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }

  const getVentasAsync=async()=>{
    const fecha = inputFecha,repDayBefore = validaFecha(AddDiasFecha(inputFecha+"T23:59:59",-1),inputFecha).fechai,
    vals={ f8:0,repEfe:0,repBank:0,equipEfe:0,equipBank:0,efeInComprobacion:0,efeOutComprobacion:0,bankInComprobacion:0,bankOutComprobacion:0,vtTotal:0,vtCredito:0,vtEfe:0,vtTar:0,vtDpb:0,servEfe:0,servBank:0,arEfe:0,arBank:0,arOutEfe:0,arOutBank:0,cobEfe:0,cobBank:0,arothinEfe :0,arothinBank:0,arothoutEfe:0,arothoutBank:0,apinEfe:0,apinBank:0,apEfe:0,apBank:0,exEfe:0,exBank:0,efeIn:0,efeOut:0,bankIn:0,bankOut:0};
    const peticionCash = {
        indexName:"cuentaIndex",
        hashKey:{valor:("CAJA#"+getSucursal()),nombre:"cuenta"},
        sortKey:{valor:[FechaLocalToUTC(fecha),FechaLocalToUTC(fecha,23,59,59)],operador:"between",nombre:"type"}
    };
    const peticionRepCash={
      hashKey:{valor:("REPORTE#$#"+getSucursal()),nombre:"id"},
      sortKey:{valor:['2023-01-01',repDayBefore],operador:"between",nombre:"type"},
      //GetFechaActualSeparado().ano+
    };
    let peticionesBank = [];

    accounts.forEach(met=>{
      const key = Object.keys(met)[0];
      const value = met[key];
      const cuenta = key+"/"+value;
     peticionesBank.push({
        indexName:"cuentaIndex",
        hashKey:{valor:(cuenta+"#"+getSucursal()),nombre:"cuenta"},
        sortKey:{valor:[FechaLocalToUTC(fecha),FechaLocalToUTC(fecha,23,59,59)],operador:"between",nombre:"type"}
      })
    })

    const peticionVentas={
      indexName:"reverseIndex",
      hashKey:{valor:("VENTA#"+getSucursal()),nombre:"type"},
      sortKey:{valor:[FechaLocalToUTC(fecha),FechaLocalToUTC(fecha,23,59,59)],operador:"between",nombre:"id"},
      filters:[{valor:'',operador:'exists',nombre:'pagado'}]
    },
    peticionPes={
      indexName:"reverseIndex",
      hashKey:{valor:("PE#"+getSucursal()),nombre:"type"},
      sortKey:{valor:[getSucursal()+"#"+FechaLocalToUTC(fecha),getSucursal()+"#"+FechaLocalToUTC(fecha,23,59,59)],operador:"between",nombre:"id"},
    };

    try{
      openmodalLoading();
      const respVentas = await getArrayAsync(peticionVentas,[]);
      const respPes = await getArrayAsync(peticionPes,[]);
      const respBank = await Promise.all(peticionesBank.map(peticion=> getArrayAsync(peticion,[]) ))
      const respCash = await getArrayAsync(peticionCash,[]);

      const respRepCash = await getArrayAsync(peticionRepCash,[]);

      respRepCash.forEach(rep=>{
        Object.keys(rep).map(account=>{
          if(rep[account]?.total){
            if(account=="CAJA"){ vals.repEfe += rep[account]?.total;  }
            else{ vals.repBank += rep[account]?.total;}
          }
        })
      });
      
      respVentas.forEach(venta=>{
        let pagado = 0;
        venta.pagos.forEach(pago=>{
          if(FechaUTCToLocal(pago.fecha).fecha==fecha){
             pagado += pago.monto; 
             if(pago.metodo=="EFECTIVO"){vals.vtEfe+=pago.monto}
             else if(pago.metodo=="TARJETA"){vals.vtTar+=pago.monto}
             else{ vals.vtDpb+=pago.monto }
          }
        });
        if(pagado<venta.total){ vals.vtCredito += venta.total-pagado;  }
        vals.vtTotal+= venta.total;
      });

      const pedidos = respPes;//.filter(pes=>(!['CANCELADO'].includes(pes.status)));
      pedidos.forEach(pe=>{
        let pagado = 0;
        pe.pagos.forEach(pago=>{
          if(FechaUTCToLocal(pago.fecha).fecha==fecha){ 
            pagado += pago.monto; 
            if(pago.metodo=="EFECTIVO"){vals.vtEfe+=pago.monto}
             else if(pago.metodo=="TARJETA"){vals.vtTar+=pago.monto}
             else{ vals.vtDpb+=pago.monto }
          }
        });
        if(pagado<pe.importe){ vals.vtCredito += pe.importe-pagado; }
        vals.vtTotal+= pe.importe;
      })
      //console.log(respCash)
      respCash.forEach(cash=>{
        if(cash.importe<0){vals.efeOut+=cash.importe*-1;}
        else{vals.efeIn+= cash.importe;}
        if(cash.clave=="AROUT"){ vals.arOutEfe += cash.importe*-1; }
        if(cash.clave=="AROTHOUT"){ vals.arothoutEfe += cash.importe*-1; }
        if(cash.clave=="AROTHIN"){ vals.arothinEfe += cash.importe; }
        if(cash.clave=="AP"){ vals.apEfe += cash.importe*-1; }
        if(cash.clave=="APIN"){ vals.apinEfe += cash.importe; }
        if(cash.clave=="SERVICIO"){ vals.servEfe += cash.importe*-1; }
        if(cash.concepto=="EX"){ vals.exEfe += cash.importe*-1; }
        if(cash.clave=="AR"){ 
          try{
            let date = shortIdToId(cash.descripcion) || "-";
            date<'2022-01-01'||date>'2100-01-01'?date = "-":null;
            if(!['-',null].includes(date)){
              const ref =  FechaUTCToLocal(date).fecha, id = FechaUTCToLocal(cash.type).fecha;
              const result = [0].includes(ref.localeCompare(id))
              //result==true?console.log(ref,'<>',id,'==>',newcash.descripcion):null
              result==true?vals.arEfe+=cash.importe:vals.cobEfe += cash.importe;
            }
          }catch (error){ null}
        }
      });

      respBank.forEach(banks=>{
        banks.forEach(bank=>{
          if(bank.importe<0){vals.bankOut+=bank.importe*-1;}
          else{vals.bankIn+= bank.importe;}
          if(bank.clave=="AROUT"){ vals.arOutBank += bank.importe*-1; }
          if(bank.clave=="AROTHOUT"){ vals.arothoutBank += bank.importe*-1; }
          if(bank.clave=="AROTHIN"){ vals.arothinBank += bank.importe; }
          if(bank.clave=="AP"){ vals.apBank+= bank.importe*-1; }
          if(bank.clave=="APIN"){ vals.apinBank += bank.importe; }
          if(bank.clave=="SERVICIO"){ vals.servBank += bank.importe*-1; }
          if(bank.concepto=="EX"){ vals.exBank += bank.importe*-1; }
          if(bank.clave=="AR"){ 
            try{
              let date = shortIdToId(bank.descripcion) || "-";
              date<'2022-01-01'||date>'2100-01-01'?date = "-":null;
              if(!['-',null].includes(date)){
                const ref =  FechaUTCToLocal(date).fecha, id = FechaUTCToLocal(bank.type).fecha;
                const result = [0].includes(ref.localeCompare(id))
                //result==true?console.log(ref,'<>',id,'==>',newcash.descripcion):null
                result==true?vals.arBank+=bank.importe:vals.cobBank += bank.importe;
              }
            }catch (error){ null}
          }
        })
      })

      closemodalLoading();
    }catch (error){
      console.log('error')
      closemodalLoading();
      handleError(error)
    }

    return vals;
  }

  return (
    < >
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Obteniendo Movimientos</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>
      

      <Modaldanger config={modalError} closeModal={closeModalError}>
        <Modaldanger.Message title={modalError?.title}>
          {modalError.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
          className="btn-gray2"
          tabIndex="200"
          onClick={closeModalError}>Cerrar</button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <div className='flex flex-col gap-4'>
        <div className="justify-center flex gap-1 items-center">
            <label className="font-semibold ">Fecha:</label>
            <input 
            type="date" 
            max={GetFechaActual().fecha}
            min={'2023-01-01'} 
            className="border-form col-span-3" 
            defaultValue={GetFechaActual().fecha}
            onBlur={e=>{
              const fecha = e.target.value;
              if(fecha<"2023-01-01"){e.target.value = "2023-01-01"}
              if(fecha>GetFechaActual().fecha){e.target.value = GetFechaActual().fecha}
              setInputFecha(e.target.value);
            }}
            />
            <button
            className='btn-blue'
            onClick={async(e)=>{
              const vals  = await getVentasAsync();
              const efeI = inputIEfe.current,bankI = inputIBank.current,bankF= inputFBank.current,efeF = inputFEfe.current;

              vals.difEfe = (vals.repEfe +vals.efeIn) - (vals.efeOut+efeF);
              vals.difBank = (vals.repBank + vals.bankIn) - (vals.bankOut+bankF);

              vals.efeInComprobacion = vals.vtEfe+vals.cobEfe+vals.arothinEfe+vals.apinEfe;
              vals.efeOutComprobacion =vals.arOutEfe+vals.arothoutEfe+vals.apEfe+vals.servEfe+vals.exEfe;
              vals.bankInComprobacion = vals.vtTar+vals.vtDpb+vals.cobBank+vals.arothinBank+vals.apinBank;
              vals.bankOutComprobacion = vals.arOutBank+vals.arothoutBank+vals.apBank+vals.servBank+vals.exBank;

              setValores(vals);
            }}>Calcular</button>

            <button
            className='btn-green'
            onClick={async(e)=>{
              const workb = await GenerateXlsx();
              if(workb?.error){
                closemodalLoading();
                handleError(workb.error);
                return
              }

              const nameFile="Corte-"+inputFecha,
              headerFile="Corte del dia "+inputFecha,
              sheet1 = workb.sheet(0);

              sheet1.column("A").width(18); 
              sheet1.column("B").width(24); 
              sheet1.column("c").width(15); 
      
              sheet1.cell("A1").value(headerFile);
              sheet1.cell("A1").style("bold",true);
              sheet1.cell("A1").style("fontSize",18);

              sheet1.range("A4:A5").merged(true);
              sheet1.cell("A4").value("DINERO");
              sheet1.cell("A4").style("bold",true);
              sheet1.cell("A4").style({horizontalAlignment: "center",verticalAlignment: "center"})
              sheet1.cell("A4").style({fill: {type: "solid",color: "aafeff"}})
              sheet1.cell("B4").value("INICIO EL DIA EFE");
              sheet1.cell("B5").value("INICIO EL DIA BANCOS");
              sheet1.cell("C4").value(valueToMoney2(inputIEfe.current));
              sheet1.cell("C5").value(valueToMoney2(inputIBank.current));
              sheet1.cell("C4").style(formatoCelda.contabilidad);
              sheet1.cell("C5").style(formatoCelda.contabilidad);
              sheet1.range("A4:C4").style({border:{top: { style: "thin", color: "000000" }}});
              sheet1.range("A5:C5").style({border:{bottom: { style: "thin", color: "000000" }}});


              sheet1.range("A6:A11").merged(true);
              sheet1.cell("A6").value("VENTAS");
              sheet1.cell("A6").style("bold",true);
              sheet1.cell("A6").style({horizontalAlignment: "center",verticalAlignment: "center"})
              sheet1.cell("A6").style({fill: {type: "solid",color: "e7e7e7"}})
              sheet1.cell("B6").value("TOTAL");
              sheet1.cell("B7").value("F8");
              sheet1.cell("B8").value("EFE");
              sheet1.cell("B9").value("TARJETA");
              sheet1.cell("B10").value("DPB TRANS");
              sheet1.cell("B11").value("CREDITO");
              sheet1.cell("C6").value(valueToMoney2(valores.vtEfe+valores.vtTar+valores.vtDpb+valores.vtCredito-valores.arOutEfe-valores.arOutBank));
              sheet1.cell("C7").value(valueToMoney2(valores.f8));
              sheet1.cell("C8").value(valueToMoney2(valores.vtEfe));
              sheet1.cell("C9").value(valueToMoney2(valores.vtTar));
              sheet1.cell("C10").value(valueToMoney2(valores.vtDpb));
              sheet1.cell("C11").value(valueToMoney2(valores.vtCredito));
              sheet1.cell("C6").style(formatoCelda.contabilidad);
              sheet1.cell("C7").style(formatoCelda.contabilidad);
              sheet1.cell("C8").style(formatoCelda.contabilidad);
              sheet1.cell("C9").style(formatoCelda.contabilidad);
              sheet1.cell("C10").style(formatoCelda.contabilidad);
              sheet1.cell("C11").style(formatoCelda.contabilidad);
              sheet1.range("B8:C8").style({fill: {type: "solid",color: "c2ffe1"}});
              sheet1.range("B9:C9").style({fill: {type: "solid",color: "64ffb4"}});
              sheet1.range("B10:C10").style({fill: {type: "solid",color: "64ffb4"}});
              sheet1.range("A11:C11").style({border:{bottom: { style: "thin", color: "000000" }}});
              

              sheet1.range("A12:A13").merged(true);
              sheet1.cell("A12").value("TK CANCELADOS");
              sheet1.cell("A12").style("bold",true);
              sheet1.cell("A12").style({horizontalAlignment: "center",verticalAlignment: "center"})
              sheet1.cell("A12").style({fill: {type: "solid",color: "aafeff"}})
              sheet1.cell("B12").value("EFE");
              sheet1.cell("B13").value("BANCOS");
              sheet1.cell("C12").value(valueToMoney2(valores.arOutEfe));
              sheet1.cell("C13").value(valueToMoney2(valores.arOutBank));
              sheet1.cell("C12").style(formatoCelda.contabilidad);
              sheet1.cell("C13").style(formatoCelda.contabilidad);
              sheet1.range("B12:C12").style({fill: {type: "solid",color: "ffffc2"}});
              sheet1.range("B13:C13").style({fill: {type: "solid",color: "fdf848"}});
              sheet1.range("A13:C13").style({border:{bottom: { style: "thin", color: "000000" }}});


              sheet1.range("A14:A15").merged(true);
              sheet1.cell("A14").value("COBRANZA");
              sheet1.cell("A14").style("bold",true);
              sheet1.cell("A14").style({horizontalAlignment: "center",verticalAlignment: "center"})
              sheet1.cell("A14").style({fill: {type: "solid",color: "e7e7e7"}})
              sheet1.cell("B14").value("EFE");
              sheet1.cell("B15").value("BANCOS");
              sheet1.cell("C14").value(valueToMoney2(valores.cobEfe));
              sheet1.cell("C15").value(valueToMoney2(valores.cobBank));
              sheet1.cell("C14").style(formatoCelda.contabilidad);
              sheet1.cell("C15").style(formatoCelda.contabilidad);
              sheet1.range("B14:C14").style({fill: {type: "solid",color: "c2ffe1"}});
              sheet1.range("B15:C15").style({fill: {type: "solid",color: "64ffb4"}});
              sheet1.range("A15:C15").style({border:{bottom: { style: "thin", color: "000000" }}});


              sheet1.range("A16:A17").merged(true);
              sheet1.cell("A16").value("AROTHER IN");
              sheet1.cell("A16").style("bold",true);
              sheet1.cell("A16").style({horizontalAlignment: "center",verticalAlignment: "center"})
              sheet1.cell("A16").style({fill: {type: "solid",color: "aafeff"}})
              sheet1.cell("B16").value("EFE");
              sheet1.cell("B17").value("BANCOS");
              sheet1.cell("C16").value(valueToMoney2(valores.arothinEfe));
              sheet1.cell("C17").value(valueToMoney2(valores.arothinBank));
              sheet1.cell("C16").style(formatoCelda.contabilidad);
              sheet1.cell("C17").style(formatoCelda.contabilidad);
              sheet1.range("B16:C16").style({fill: {type: "solid",color: "c2ffe1"}});
              sheet1.range("B17:C17").style({fill: {type: "solid",color: "64ffb4"}});
              sheet1.range("A17:C17").style({border:{bottom: { style: "thin", color: "000000" }}});


              sheet1.range("A18:A19").merged(true);
              sheet1.cell("A18").value("AROTHER OUT");
              sheet1.cell("A18").style("bold",true);
              sheet1.cell("A18").style({horizontalAlignment: "center",verticalAlignment: "center"})
              sheet1.cell("A18").style({fill: {type: "solid",color: "e7e7e7"}})
              sheet1.cell("B18").value("EFE");
              sheet1.cell("B19").value("BANCOS");
              sheet1.cell("C18").value(valueToMoney2(valores.arothoutEfe));
              sheet1.cell("C19").value(valueToMoney2(valores.arothoutBank));
              sheet1.cell("C18").style(formatoCelda.contabilidad);
              sheet1.cell("C19").style(formatoCelda.contabilidad);
              sheet1.range("B18:C18").style({fill: {type: "solid",color: "ffffc2"}});
              sheet1.range("B19:C19").style({fill: {type: "solid",color: "fdf848"}});
              sheet1.range("A19:C19").style({border:{bottom: { style: "thin", color: "000000" }}});


              sheet1.range("A20:A21").merged(true);
              sheet1.cell("A20").value("AP");
              sheet1.cell("A20").style("bold",true);
              sheet1.cell("A20").style({horizontalAlignment: "center",verticalAlignment: "center"})
              sheet1.cell("A20").style({fill: {type: "solid",color: "aafeff"}})
              sheet1.cell("B20").value("EFE");
              sheet1.cell("B21").value("BANCOS");
              sheet1.cell("C20").value(valueToMoney2(valores.apEfe));
              sheet1.cell("C21").value(valueToMoney2(valores.apBank));
              sheet1.cell("C20").style(formatoCelda.contabilidad);
              sheet1.cell("C21").style(formatoCelda.contabilidad);
              sheet1.range("B20:C20").style({fill: {type: "solid",color: "ffffc2"}});
              sheet1.range("B21:C21").style({fill: {type: "solid",color: "fdf848"}});
              sheet1.range("A21:C21").style({border:{bottom: { style: "thin", color: "000000" }}});


              sheet1.range("A22:A23").merged(true);
              sheet1.cell("A22").value("PRESTAMOS AP");
              sheet1.cell("A22").style("bold",true);
              sheet1.cell("A22").style({horizontalAlignment: "center",verticalAlignment: "center"})
              sheet1.cell("A22").style({fill: {type: "solid",color: "e7e7e7"}})
              sheet1.cell("B22").value("EFE");
              sheet1.cell("B23").value("BANCOS");
              sheet1.cell("C22").value(valueToMoney2(valores.apinEfe));
              sheet1.cell("C23").value(valueToMoney2(valores.apinBank));
              sheet1.cell("C22").style(formatoCelda.contabilidad);
              sheet1.cell("C23").style(formatoCelda.contabilidad);
              sheet1.range("B22:C22").style({fill: {type: "solid",color: "c2ffe1"}});
              sheet1.range("B23:C23").style({fill: {type: "solid",color: "64ffb4"}});
              sheet1.range("A23:C23").style({border:{bottom: { style: "thin", color: "000000" }}});


              sheet1.range("A24:A25").merged(true);
              sheet1.cell("A24").value("SERVICIOS");
              sheet1.cell("A24").style("bold",true);
              sheet1.cell("A24").style({horizontalAlignment: "center",verticalAlignment: "center"})
              sheet1.cell("A24").style({fill: {type: "solid",color: "aafeff"}})
              sheet1.cell("B24").value("EFE");
              sheet1.cell("B25").value("BANCOS");
              sheet1.cell("C24").value(valueToMoney2(valores.servEfe));
              sheet1.cell("C25").value(valueToMoney2(valores.servBank));
              sheet1.cell("C24").style(formatoCelda.contabilidad);
              sheet1.cell("C25").style(formatoCelda.contabilidad);
              sheet1.range("B24:C24").style({fill: {type: "solid",color: "ffffc2"}});
              sheet1.range("B25:C25").style({fill: {type: "solid",color: "fdf848"}});
              sheet1.range("A25:C25").style({border:{bottom: { style: "thin", color: "000000" }}});


              sheet1.range("A26:A27").merged(true);
              sheet1.cell("A26").value("EX (GASTOS)");
              sheet1.cell("A26").style("bold",true);
              sheet1.cell("A26").style({horizontalAlignment: "center",verticalAlignment: "center"})
              sheet1.cell("A26").style({fill: {type: "solid",color: "e7e7e7"}})
              sheet1.cell("B26").value("EFE");
              sheet1.cell("B27").value("BANCOS");
              sheet1.cell("C26").value(valueToMoney2(valores.exEfe));
              sheet1.cell("C27").value(valueToMoney2(valores.exBank));
              sheet1.cell("C26").style(formatoCelda.contabilidad);
              sheet1.cell("C27").style(formatoCelda.contabilidad);
              sheet1.range("B26:C26").style({fill: {type: "solid",color: "ffffc2"}});
              sheet1.range("B27:C27").style({fill: {type: "solid",color: "fdf848"}});
              sheet1.range("A27:C27").style({border:{bottom: { style: "thin", color: "000000" }}});


              sheet1.range("A28:A29").merged(true);
              sheet1.cell("A28").value("EQUIPOS");
              sheet1.cell("A28").style("bold",true);
              sheet1.cell("A28").style({horizontalAlignment: "center",verticalAlignment: "center"})
              sheet1.cell("A28").style({fill: {type: "solid",color: "aafeff"}})
              sheet1.cell("B28").value("EFE");
              sheet1.cell("B29").value("BANCOS");
              sheet1.cell("C28").value(valueToMoney2(valores.equipEfe));
              sheet1.cell("C29").value(valueToMoney2(valores.equipBank));
              sheet1.cell("C28").style(formatoCelda.contabilidad);
              sheet1.cell("C29").style(formatoCelda.contabilidad);
              sheet1.range("B28:C28").style({fill: {type: "solid",color: "ffffc2"}});
              sheet1.range("B29:C29").style({fill: {type: "solid",color: "fdf848"}});
              sheet1.range("A29:C29").style({border:{bottom: { style: "thin", color: "000000" }}});


              sheet1.range("A30:A33").merged(true);
              sheet1.cell("A30").value("TOTALES");
              sheet1.cell("A30").style("bold",true);
              sheet1.cell("A30").style({horizontalAlignment: "center",verticalAlignment: "center"})
              sheet1.cell("A30").style({fill: {type: "solid",color: "ffffff"}})
              sheet1.cell("B30").value("EXISTENCIA FISICO EFE");
              sheet1.cell("B31").value("DIFERENCIA EFE");
              sheet1.cell("B32").value("EXISTENCIA FISICO BANCOS");
              sheet1.cell("B33").value("DIFERENCIA BANCOS");
              sheet1.cell("C30").value(valueToMoney2(inputFEfe.current));
              sheet1.cell("C31").value(valueToMoney2(valores.difEfe));
              sheet1.cell("C32").value(valueToMoney2(inputFBank.current));
              sheet1.cell("C33").value(valueToMoney2(valores.difBank));
              sheet1.cell("C30").style(formatoCelda.contabilidad);
              sheet1.cell("C31").style(formatoCelda.contabilidad);
              sheet1.cell("C32").style(formatoCelda.contabilidad);
              sheet1.cell("C33").style(formatoCelda.contabilidad);
              sheet1.cell("B31").style({fill: {type: "solid",color: "be0000"},fontColor: "FFFFFF"});
              sheet1.cell("B33").style({fill: {type: "solid",color: "be0000"},fontColor: "FFFFFF"});
              sheet1.range("A33:C33").style({border:{bottom: { style: "thin", color: "000000" }}});


              sheet1.range("A34:A37").merged(true);
              sheet1.cell("A34").value("COMPROBACION EFE");
              sheet1.cell("A34").style("bold",true);
              sheet1.cell("A34").style("wrapText", true);
              sheet1.cell("A34").style({horizontalAlignment: "center",verticalAlignment: "center"})
              sheet1.cell("A34").style({fill: {type: "solid",color: "faaeae"}});
              sheet1.cell("B34").value("EMPIEZA PRO");
              sheet1.cell("B35").value("ENTRA");
              sheet1.cell("B36").value("SALE");
              sheet1.cell("B37").value("TOTAL");
              sheet1.cell("C34").value(valueToMoney2(valores.repEfe));
              sheet1.cell("C35").value(valueToMoney2(valores.efeIn));
              sheet1.cell("C36").value(valueToMoney2(valores.efeOut));
              sheet1.cell("C37").value(valueToMoney2(valores.repEfe+valores.efeIn-valores.efeOut));
              sheet1.cell("C34").style(formatoCelda.contabilidad);
              sheet1.cell("C35").style(formatoCelda.contabilidad);
              sheet1.cell("C36").style(formatoCelda.contabilidad);
              sheet1.cell("C37").style(formatoCelda.contabilidad);
              sheet1.range("B35:C35").style({fill: {type: "solid",color: "c2ffe1"}});
              sheet1.range("B36:C36").style({fill: {type: "solid",color: "ffffc2"}});
              sheet1.range("A37:C37").style({border:{bottom: { style: "thin", color: "000000" }}});


              sheet1.range("A38:A41").merged(true);
              sheet1.cell("A38").value("COMPROBACION BANCOS");
              sheet1.cell("A38").style("bold",true);
              sheet1.cell("A38").style("wrapText", true);
              sheet1.cell("A38").style({horizontalAlignment: "center",verticalAlignment: "center"})
              sheet1.cell("A38").style({fill: {type: "solid",color: "faaeae"}})
              sheet1.cell("B38").value("EMPIEZA PRO");
              sheet1.cell("B39").value("ENTRA");
              sheet1.cell("B40").value("SALE");
              sheet1.cell("B41").value("TOTAL");
              sheet1.cell("C38").value(valueToMoney2(valores.repBank));
              sheet1.cell("C39").value(valueToMoney2(valores.bankIn));
              sheet1.cell("C40").value(valueToMoney2(valores.bankOut));
              sheet1.cell("C41").value(valueToMoney2(valores.repBank+valores.bankIn-valores.bankOut));
              sheet1.cell("C38").style(formatoCelda.contabilidad);
              sheet1.cell("C39").style(formatoCelda.contabilidad);
              sheet1.cell("C40").style(formatoCelda.contabilidad);
              sheet1.cell("C41").style(formatoCelda.contabilidad);
              sheet1.range("B39:C39").style({fill: {type: "solid",color: "64ffb4"}})
              sheet1.range("B40:C40").style({fill: {type: "solid",color: "fdf848"}});
              sheet1.range("A41:C41").style({border:{bottom: { style: "thin", color: "000000" }}});

              sheet1.range("D4:D41").style({border:{left: { style: "thin", color: "000000" },}});


              const response =await SaveXlsx(workb,nameFile);
              if(response?.error){
                closemodalLoading();
                handleError(response.error);
                return
              }
              closemodalLoading();
              openModalSuccess("Archivo generado correctamente","Descarga Completa");
            }}>Descargar</button>
        </div>
        <div className='flex flex-col gap-2 w-full md:w-1/2 lg:w-1/4'>
          <div className="grid grid-rows-2 grid-cols-3 w-full ">
            <label className="font-bold row-span-2 flex items-center justify-center bg-sky-100 rounded-lg">DINERO:</label>
            <div className="flex gap-1 items-center col-span-2">
              <label className="font-bold row-span-2 w-full">INICIO EL DIA EN CAJA:</label>
              <InputPrecioPanel
              className="bg-white w-36"
              form={true}
              min={0}
              newValor={0}
              placeholder="limite de Credito"
              onBlur={valor=>{ inputIEfe.current = moneyToValue(valor); }}
              />
            </div>
            <div className="flex gap-1 items-center col-span-2">
              <label className="font-bold row-span-2 w-full">INICIO EL DIA EN BANCOS:</label>
              <InputPrecioPanel
              className="bg-white w-36"
              form={true}
              min={0}
              newValor={0}
              placeholder="limite de Credito"
              onBlur={valor=>{ inputIBank.current = moneyToValue(valor); }}
              />
            </div>
          </div>
        
          <div className="grid grid-flow-row grid-cols-3">
            <label className="font-bold row-span-6 flex items-center justify-center ">VENTAS:</label>
            <div className="flex gap-1 items-center col-span-2 ">
              <label className="font-bold row-span-2 w-full" title="F8+EFE+TARJETA+DPB+CREDITO-CANCELADOS/DEVOLUCIONES">TOTAL:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.vtEfe+valores.vtTar+valores.vtDpb+valores.vtCredito-valores.arOutEfe-valores.arOutBank}</Labelmoney>
            </div>
            <div className="flex gap-1 items-center col-span-2 ">
              <label className="font-bold row-span-2 w-full">F8:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.f8}</Labelmoney>
            </div>
            {/* <div className="flex gap-1 items-center col-span-2 ">
              <label className="font-bold row-span-2 w-full">EFE:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.arEfe}</Labelmoney>
            </div> */}
            <div className="flex gap-1 items-center col-span-2 bg-teal-100 rounded-sm">
              <label className="font-bold row-span-2 w-full">EFE:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.vtEfe}</Labelmoney>
            </div>
            <div className="flex gap-1 items-center col-span-2 bg-teal-200 rounded-sm">
              <label className="font-bold row-span-2 w-full">TARJETA:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.vtTar}</Labelmoney>
            </div>
            <div className="flex gap-1 items-center col-span-2 bg-teal-200 rounded-sm">
              <label className="font-bold row-span-2 w-full">DPB,TRANS:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.vtDpb}</Labelmoney>
            </div>
            <div className="flex gap-1 items-center col-span-2 ">
              <label className="font-bold row-span-2 w-full">CREDITO:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.vtCredito}</Labelmoney>
            </div>
          </div>

          <div className="grid grid-flow-row grid-cols-3">
            <label className="font-bold row-span-2 flex items-center justify-center bg-sky-100 rounded-lg">TK CANCELADOS:</label>
            <div className="flex gap-1 items-center col-span-2 bg-ambar-100 rounded-sm">
              <label className="font-bold row-span-2 w-full">EFE:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.arOutEfe}</Labelmoney>
            </div>
            <div className="flex gap-1 items-center col-span-2 bg-ambar-200 rounded-sm">
              <label className="font-bold row-span-2 w-full">BANCOS:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.arOutBank}</Labelmoney>
            </div>
          </div>

          <div className="grid grid-flow-row grid-cols-3">
            <label className="font-bold row-span-2 flex items-center justify-center">COBRANZA:</label>
            <div className="flex gap-1 items-center col-span-2 bg-teal-100 rounded-sm">
              <label className="font-bold row-span-2 w-full">EFE:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.cobEfe}</Labelmoney>
            </div>
            <div className="flex gap-1 items-center col-span-2 bg-teal-200 rounded-sm">
              <label className="font-bold row-span-2 w-full">BANCOS:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.cobBank}</Labelmoney>
            </div>
          </div>

          <div className="grid grid-flow-row grid-cols-3">
            <label className="font-bold row-span-2 flex items-center justify-center bg-sky-100 rounded-lg">AROTHER IN:</label>
            <div className="flex gap-1 items-center col-span-2 bg-teal-100 rounded-sm">
              <label className="font-bold row-span-2 w-full">EFE:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.arothinEfe}</Labelmoney>
            </div>
            <div className="flex gap-1 items-center col-span-2 bg-teal-200 rounded-sm">
              <label className="font-bold row-span-2 w-full">BANCOS:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.arothinBank}</Labelmoney>
            </div>
          </div>

          <div className="grid grid-flow-row grid-cols-3">
            <label className="font-bold row-span-2 flex items-center justify-center">AROTHER OUT:</label>
            <div className="flex gap-1 items-center col-span-2 bg-ambar-100 rounded-sm">
              <label className="font-bold row-span-2 w-full ">EFE:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.arothoutEfe}</Labelmoney>
            </div>
            <div className="flex gap-1 items-center col-span-2 bg-ambar-200 rounded-sm">
              <label className="font-bold row-span-2 w-full">BANCOS:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.arothoutBank}</Labelmoney>
            </div>
          </div>

          <div className="grid grid-flow-row grid-cols-3">
            <label className="font-bold row-span-2 flex items-center justify-center bg-sky-100 rounded-lg">PAGOS AP:</label>
            <div className="flex gap-1 items-center col-span-2 bg-ambar-100 rounded-sm">
              <label className="font-bold row-span-2 w-full">EFE:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.apEfe}</Labelmoney>
            </div>
            <div className="flex gap-1 items-center col-span-2 bg-ambar-200 rounded-sm">
              <label className="font-bold row-span-2 w-full">BANCOS:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.apBank}</Labelmoney>
            </div>
          </div>

          <div className="grid grid-flow-row grid-cols-3">
            <label className="font-bold row-span-2 flex items-center justify-center">PRESTAMOS DE AP:</label>
            <div className="flex gap-1 items-center col-span-2 bg-teal-100 rounded-sm">
              <label className="font-bold row-span-2 w-full">EFE:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.apinEfe}</Labelmoney>
            </div>
            <div className="flex gap-1 items-center col-span-2 bg-teal-200 rounded-sm">
              <label className="font-bold row-span-2 w-full">BANCOS:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.apinBank}</Labelmoney>
            </div>
          </div>

          <div className="grid grid-flow-row grid-cols-3">
            <label className="font-bold row-span-2 flex items-center justify-center bg-sky-100 rounded-lg">SERVICIOS:</label>
            <div className="flex gap-1 items-center col-span-2 bg-ambar-100 rounded-sm">
              <label className="font-bold row-span-2 w-full">EFE:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.servEfe}</Labelmoney>
            </div>
            <div className="flex gap-1 items-center col-span-2 bg-ambar-200 rounded-sm">
              <label className="font-bold row-span-2 w-full">BANCOS:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.servBank}</Labelmoney>
            </div>
          </div>

          <div className="grid grid-flow-row grid-cols-3">
            <label className="font-bold row-span-2 flex items-center justify-center bg-sky-100 rounded-lg">GASTOS (EX):</label>
            <div className="flex gap-1 items-center col-span-2 bg-ambar-100 rounded-sm">
              <label className="font-bold row-span-2 w-full">EFE:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.exEfe}</Labelmoney>
            </div>
            <div className="flex gap-1 items-center col-span-2 bg-ambar-200 rounded-sm">
              <label className="font-bold row-span-2 w-full">BANCOS:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.exBank}</Labelmoney>
            </div>
          </div>

          <div className="grid grid-flow-row grid-cols-3">
            <label className="font-bold row-span-2 flex items-center justify-center">INVERSION DE EQUIPO:</label>
            <div className="flex gap-1 items-center col-span-2 bg-ambar-100 rounded-sm">
              <label className="font-bold row-span-2 w-full">EFE:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.equipEfe}</Labelmoney>
            </div>
            <div className="flex gap-1 items-center col-span-2 bg-ambar-200 rounded-sm">
              <label className="font-bold row-span-2 w-full">BANCOS:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.equipBank}</Labelmoney>
            </div>
          </div>

          <div className="grid grid-flow-row grid-cols-3">
            <label className="font-bold row-span-6 flex items-center justify-center bg-gray-200">TOTALES:</label>
            {/* <div className="flex gap-1 items-center col-span-2 ">
              <label className="font-bold row-span-2 w-full">TOTAL EFE:</label>
              <Labelmoney className="w-48" twoDecimal={true}>1000</Labelmoney>
            </div> */}
            <div className="flex gap-1 items-center col-span-2 ">
              <label className="font-bold row-span-2 w-full">EXISTENCIA EFE FISICO:</label>
              <InputPrecioPanel
              className="bg-white w-36"
              form={true}
              min={0}
              newValor={0}
              placeholder="limite de Credito"
              onBlur={valor=>{ inputFEfe.current = moneyToValue(valor); }}
              />
            </div>
            <div className="flex gap-1 items-center col-span-2 ">
              <label className="font-bold row-span-2 w-full bg-red-600 text-white rounded-md">DIF EFE:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.difEfe}</Labelmoney>
            </div>
            {/* <div className="flex gap-1 items-center col-span-2 ">
              <label className="font-bold row-span-2 w-full">TOTAL BANCOS:</label>
              <Labelmoney className="w-48" twoDecimal={true}>10000000000</Labelmoney>
            </div> */}
            <div className="flex gap-1 items-center col-span-2 ">
              <label className="font-bold row-span-2 w-full">EXISTENCIA BANCOS FISICO:</label>
              <InputPrecioPanel
              className="bg-white w-36"
              form={true}
              min={0}
              newValor={0}
              placeholder="limite de Credito"
              onBlur={valor=>{ inputFBank.current = moneyToValue(valor); }}
              />
            </div>
            <div className="flex gap-1 items-center col-span-2 ">
              <label className="font-bold row-span-2 w-full bg-red-600 text-white rounded-md">DIF BANCOS:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.difBank}</Labelmoney>
            </div>
          </div>

          <div className="grid grid-flow-row grid-cols-3 ">
            <label className="font-bold row-span-4 flex items-center justify-center bg-red-200 rounded-lg">COMPROBACION EFE:</label>
            <div className="flex gap-1 items-center col-span-2 ">
              <label className={"font-bold row-span-2 w-full "+(valores.bankIn!=valores.bankInComprobacion?"text-red-500":"")} title={""+valueToMoney2(valores.bankInComprobacion)}>EMPIEZA:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.repEfe}</Labelmoney>
            </div>
            <div className="flex gap-1 items-center col-span-2 bg-teal-100 rounded-sm">
              <label className={"font-bold row-span-2 w-full "+(valores.efeIn!=valores.efeInComprobacion?" text-red-500":"")} title={valueToMoney2(valores.efeInComprobacion)}>ENTRA:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.efeIn}</Labelmoney>
            </div>
            <div className="flex gap-1 items-center col-span-2 bg-ambar-100 rounded-sm">
              <label className={"font-bold row-span-2 w-full "+(valores.efeOut!=valores.efeOutComprobacion?" text-red-500":"")} title={valueToMoney2(valores.efeOutComprobacion)}>SALE:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.efeOut}</Labelmoney>
            </div>
            <div className="flex gap-1 items-center col-span-2 ">
              <label className="font-bold row-span-2 w-full">TOTAL:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.repEfe+valores.efeIn-valores.efeOut}</Labelmoney>
            </div>
          </div>

          <div className="grid grid-flow-row grid-cols-3">
            <label className="font-bold row-span-4 flex items-center justify-center bg-red-200 rounded-lg">COMPROBACION BANCOS:</label>
            <div className="flex gap-1 items-center col-span-2 ">
              <label className={"font-bold row-span-2 w-full "+(valores.bankIn!=valores.bankInComprobacion?"text-red-500":"")} title={""+valueToMoney2(valores.bankInComprobacion)}>EMPIEZA MESH:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.repBank}</Labelmoney>
            </div>
            <div className="flex gap-1 items-center col-span-2 bg-teal-200 rounded-sm">
              <label className={"font-bold row-span-2 w-full "+(valores.bankIn!=valores.bankInComprobacion?"text-red-500":"")} title={""+valueToMoney2(valores.bankInComprobacion)}>ENTRA:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.bankIn}</Labelmoney>
            </div>
            <div className="flex gap-1 items-center col-span-2 bg-ambar-200 rounded-sm">
              <label className={"font-bold row-span-2 w-full "+(valores.bankOut!=valores.bankOutComprobacion?"text-red-500":"")} title={""+valueToMoney2(valores.bankOutComprobacion)}>SALE:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.bankOut}</Labelmoney>
            </div>
            <div className="flex gap-1 items-center col-span-2 ">
              <label className="font-bold row-span-2 w-full">TOTAL:</label>
              <Labelmoney className="w-48" twoDecimal={true}>{valores.repBank+valores.bankIn-valores.bankOut}</Labelmoney>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos
});

export default connect(mapStateToProps, mapDispatchToProps)(ReporteCorteDia)