import React, { useState, useRef, useEffect} from "react";
import { CalcularPorcentajeMargenUtilidad, FechaLocalToUTC, FechaUTCToLocal, GetFechaActual, OrderArrayAZ, apiUsePromise, getArrayAsync, getSucursal, getTotal, tipoCelda, valueToMoney2 } from "../../../services/functions";
import Modalinfo from "../../modal/info";
import { connect } from "react-redux";
import LoadingModal from "../../modal/LoadingModal";
import Modaldanger from "../../modal/danger";
import TableReactWindows from "../Cash/TableReactWindows";
import { setRutaRep } from "../../../redux/Actions";
import { GenerateXlsx, SaveXlsx, columnasXlsx, formatoCelda } from "../../Excel/DataToXlsx";
import Modalsuccess from "../../modal/success";

const PanelReporteVentas = ({setRutaRep,PropsReportes,setHeader,addDatos,Datos,setPropsRep}) => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [ventas,setVentas]=useState([]),
  [modalLoading, setmodalLoading] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({});
  const title = useRef("Sin Datos");
  const nameComponent = "ventasRango",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1];

  const fechaI = FechaLocalToUTC(PropsReportes.fecha_Inicial),fechaF=FechaLocalToUTC(PropsReportes.fecha_Final,23,59,59);


  function getMargenTotal(array,type) {
    const utilidadTotal = array.reduce((a, b) => parseInt(a) + parseInt(b.utilidad), 0);
    const total = array.reduce((a, b) => parseInt(a) + parseInt(b.totalVendido), 0);
    return CalcularPorcentajeMargenUtilidad(utilidadTotal,total);
  }


  const handleError = (error) => {
    setmodaldangererrorConfig({
      isOpen: true,
      message:  error?.message || error?.response?.data?.message,
    });
  }

  function closeModalinfo(e) {
    setmodalinfoConfig({ isOpen: false });
  }
  function closeModaldangererror(e) {
      const newdataconfig = { ...modaldangererrorConfig };
      newdataconfig.isOpen = false;
      setmodaldangererrorConfig(newdataconfig);
  }

  const openmodalLoading = (e) => {
    setmodalLoading({ isOpen: true });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };
  const openModalSuccess=(message,titulo)=>{
    setmodalSuccessConfig({
      isOpen: true,
      title: titulo||"Solicitud Completa",
      message: message,        
    });
  }
  const closeModalsuccess=(e)=> {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }

  
  const handleSuccessfulRepVentas=(data)=>{
    let new_datos= data.filter(venta=>venta.totalVendido>0);
    new_datos = new_datos.map(venta=>({...venta,itemsPes:[]}));
    return new_datos;
  }
  const handleSuccessfulVentaHoy=(balance,data)=>{
    let new_balan = [...balance];
    const venta = data,venta_today={};

    venta_today.type = GetFechaActual().fecha;
    venta_today.descuento = venta.reduce((a, b) => parseInt(a) + parseInt(b.descuentoTotal), 0);
    venta_today.iva = venta.reduce((a, b) => parseInt(a) + parseInt(b.iva), 0);
    venta_today.totalVendido = venta.reduce((a, b) => parseInt(a) + parseInt(b.total), 0);
    venta_today.utilidad = venta.reduce((a, b) => parseInt(a) + parseInt(b.utilidad), 0);
    venta_today.numeroArticulos = venta.reduce((a,b)=>parseInt(a) + parseInt(b.numeroArticulos),0);
    venta_today.itemsPes=[];

    new_balan.push(venta_today);
    return new_balan;
  }
  const handleSuccessfulPes=(balance,data)=>{
    let new_balan = [...balance];
    //console.log('pes=>',data)
    const pedidos = data.filter(pes=>(!['CANCELADO'].includes(pes.status)));//'SOLICITADO'
    let arrayPes = [...new Set(pedidos.map(pes => FechaUTCToLocal(pes.id.split('#')[1]).fecha))].sort();
    arrayPes = arrayPes.map(date=>({"fecha":date,"datos":[]}));
    //console.log(arrayPes)

    pedidos.forEach(pes => {
      const fechaPes= FechaUTCToLocal(pes.id.split('#')[1]).fecha;
      const findBalance = new_balan.filter(balan=>balan.type==fechaPes).length?true:false;
      if(!findBalance){
        new_balan.push({type:fechaPes,descuento:0, iva:0,totalVendido:0,utilidad:0,numeroArticulos:0,itemsPes:[]});
      }
        new_balan.map(rep=>{
            if(rep.type==fechaPes){
              const pv = pes?.importe||0, pc =pes?.precioCompra||0;
              rep.totalVendido += pv;
              rep.utilidad += (pv-pc);
              rep.numeroArticulos += pes.items.reduce((ia,ib)=>parseInt(ia) + parseInt(ib.cantidad), 0);

              const items= pes.items.map(item=>(
                {...item,
                  cantidadVendida: item.cantidad,
                  utilidad: item.precioVenta - pc,
                  precioCompra: pc,
                  importe: item.precioVenta,
                  descuento: 0
                }
              ))
              rep.itemsPes.push({
                shortId: pes.shortId,
                vendedor:pes.personal,
                totalPrecioCompra: pes.precioCompra,
                total: pv,
                nombreVendedor:pes.nombrePersonal,
                numeroArticulos: pes.items.reduce((ia,ib)=>parseInt(ia) + parseInt(ib.cantidad), 0),
                iva: 0,
                nombreCliente : "",
                subtotal: pv,
                cliente: pes.cliente,
                sucursal: pes.sucursal,
                pagado: pes.pagado,
                id: pes.id.split('#')[1],
                idPes: pes.id,
                type: pes.type.split('#')[0],

      
                items: items,
                status: pes.status,
                fechaStatus: pes.fechaStatus,
              })
            }
        });
    });
    return new_balan;
  }

  useEffect(() => {
    if(rutaActualRep!==nameComponent){return;}
    const newPropsRep = {...PropsReportes,header:{btns:{detail:true,by:true}}};
    if(newPropsRep.header?.text!=="Sales by Date"){ newPropsRep.header.text = "Sales by Date"}

    const datos = Datos[nameComponent]||{};
    const getVentasAsync=async()=>{
      openmodalLoading();
      //const fechaI = FechaLocalToUTC(PropsReportes.fecha_Inicial),fechaF=FechaLocalToUTC(PropsReportes.fecha_Final,23,59,59);
      let reporteVentas=[];
      let peticiones =[
        {
          hashKey:{valor:("REPORTE#VENTA#"+getSucursal()),nombre:"id"},
          sortKey:{valor:[PropsReportes.fecha_Inicial,PropsReportes.fecha_Final],operador:"between",nombre:"type"}
        },
        {
          indexName:"reverseIndex",
          hashKey:{valor:("VENTA#"+getSucursal()),nombre:"type"},
          sortKey:{valor:[FechaLocalToUTC(GetFechaActual().fecha),FechaLocalToUTC(GetFechaActual().fecha,23,59,59)],operador:"between",nombre:"id"},
          filters:[{valor:'',operador:'exists',nombre:'pagado'}]
        },
        {
          indexName:"reverseIndex",
          hashKey:{valor:("PE#"+getSucursal()),nombre:"type"},
          sortKey:{valor:[getSucursal()+"#"+fechaI,getSucursal()+"#"+fechaF],operador:"between",nombre:"id"},
        }
      ]

      try{
        const reporteFecha = await getArrayAsync(peticiones[0],[]);
        reporteVentas = handleSuccessfulRepVentas(reporteFecha);

        if(PropsReportes.fecha_Final==GetFechaActual().fecha){
          const reporteHoy= await getArrayAsync(peticiones[1],[]);
          reporteVentas = handleSuccessfulVentaHoy(reporteVentas,reporteHoy);

        }
        //console.log(reporteVentas)

        const reportePes = await getArrayAsync(peticiones[2],[]);
        reporteVentas = handleSuccessfulPes(reporteVentas,reportePes);
        //console.log(reportePes)

        reporteVentas.map(venta=>(
          venta.subtotal =venta?.totalVendido+venta?.descuento,
          venta.margen =CalcularPorcentajeMargenUtilidad(venta?.utilidad,venta?.totalVendido)
        ));
        reporteVentas = OrderArrayAZ(reporteVentas,'type','string');

        newPropsRep.select = reporteVentas[0]?.type;

        setPropsRep(newPropsRep)
        setVentas(reporteVentas);
        addDatos(nameComponent,[reporteVentas,{fechai:PropsReportes.fecha_Inicial,fechaf:PropsReportes.fecha_Final}]);
        closemodalLoading();
      }catch(error){
        closemodalLoading();
        handleError(error)
      }
    }
   
    if(JSON.stringify(datos)=='{}'){
      getVentasAsync(); 
      return;
    }
    if(PropsReportes.fecha_Inicial==datos[1].fechai&&PropsReportes.fecha_Final==datos[1].fechaf){
      const reporteVentas =datos[0];
      setVentas(reporteVentas);
      newPropsRep.select = reporteVentas[0].type;
      setPropsRep(newPropsRep)
      return;
    }
    getVentasAsync();
  }, [PropsReportes.fecha_Inicial,PropsReportes.fecha_Final,PropsReportes.rutas]);


  
  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargando Reporte</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

      <Modalsuccess config={modalSuccessConfig} tab="200" closeModal={closeModalsuccess}>
        <Modalsuccess.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"200"}
            className={"btn-green"}
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>

     <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={"Cantidad no válida"}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <TableReactWindows
        btnDownload={async(e,datos)=>{
          const data = datos || ventas;
          if(!data?.length){handleError("No hay datos que exportar"); return;}
          openmodalLoading();
          const workb = await GenerateXlsx();
          if(workb?.error){
            closemodalLoading();
            handleError(workb.error);
            return
          }

          const nameFile="Ventas",
          headerFile="Reporte de Ventas del "+PropsReportes?.fecha_Inicial+" al "+PropsReportes?.fecha_Final,
          sheet1 = workb.sheet(0),colPlus = 4;

          sheet1.cell("A1").value(headerFile);
          sheet1.cell("A1").style("bold",true);
          sheet1.cell("A1").style("fontSize",18);
      
          const sizeData = data.length + colPlus,
          header={'Fecha':13,'Cant':10,'Ventas':17,'Descuento':17,'Importe':17,'Profit':15,'Margen':10};
      
          Object.keys(header).map((key,index)=>{
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
            sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
            sheet1.column(columnasXlsx[index]).width(header[key]); 
          })

          data.forEach((venta,index) => {
            sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(venta.type);
            sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(venta?.numeroArticulos||0);
            sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(valueToMoney2(venta?.subtotal||0));
            sheet1.cell(columnasXlsx[2]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[3]+(colPlus+index)).value(valueToMoney2(venta?.descuento||0));
            sheet1.cell(columnasXlsx[3]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).value(valueToMoney2(venta?.totalVendido||0));
            sheet1.cell(columnasXlsx[4]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[5]+(colPlus+index)).value(valueToMoney2(venta?.utilidad||0));
            sheet1.cell(columnasXlsx[5]+(colPlus+index)).style(formatoCelda.contabilidad);
            sheet1.cell(columnasXlsx[6]+(colPlus+index)).value((venta?.margen/100)||0);
            sheet1.cell(columnasXlsx[6]+(colPlus+index)).style("numberFormat", "0.00%");
          });
      
          sheet1.cell(columnasXlsx[1]+sizeData).formula("SUM("+columnasXlsx[1]+""+colPlus+":"+columnasXlsx[1]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[2]+sizeData).formula("SUM("+columnasXlsx[2]+""+colPlus+":"+columnasXlsx[2]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[2]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[3]+sizeData).formula("SUM("+columnasXlsx[3]+""+colPlus+":"+columnasXlsx[3]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[3]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[4]+sizeData).formula("SUM("+columnasXlsx[4]+""+colPlus+":"+columnasXlsx[4]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[4]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[5]+sizeData).formula("SUM("+columnasXlsx[5]+""+colPlus+":"+columnasXlsx[5]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[5]+sizeData).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[6]+sizeData).formula("AVERAGE("+columnasXlsx[6]+""+colPlus+":"+columnasXlsx[6]+""+(sizeData-1)+")");
          sheet1.cell(columnasXlsx[6]+sizeData).style("numberFormat", "0.00%");
          
          const response =await SaveXlsx(workb,nameFile);
          if(response?.error){
            closemodalLoading();
            handleError(response.error);
            return
          }
          closemodalLoading();
          openModalSuccess("Archivo generado correctamente","Descarga Completa");
        }}
        onRowClick={({e,row})=>{ if(e.detail==1){ setPropsRep({...PropsReportes,select:row.type}) } }}
        columns={[
          {
            key:"type",
            header: "Date",
            noUtc:true,
            type:tipoCelda.date,
            hideTip:true,
            width:100,
            filter:true,
            onClick: ({e,row}) => {
                if(e.detail==2){
                  setHeader({...PropsReportes.header,day:row.type});
                  setRutaRep("ventasDay");
                }
            },
            footer:"Total:",
          },
          {
            key:"numeroArticulos",
            header: "QtySold",
            type:tipoCelda.number,
            width:75,
            footer:true,
            function:getTotal,
            filter:true,
          },
          {
            key:"subtotal",
            header: "Sales",
            type:tipoCelda.money,
            width:125,
            className:"text-blue-800",
            filter:true,
            footer:true,
            function:getTotal,
          },
          {
            key:"iva",
            header: "IVA",
            width:120,
            type:tipoCelda.money,
          },
          {
            key:"descuento",
            header: "Discount",
            width:120,
            type:tipoCelda.money,
            className:"text-pink-800",
            filter:true,
            footer:true,
            function:getTotal,
          },
          {
            key:"totalVendido",
            header: "Total",
            width:120,
            type:tipoCelda.money,
            filter:true,
            footer:true,
            function:getTotal,
          },
          {
            key:"utilidad",
            header: "Profit",
            width:120,
            type:tipoCelda.money,
            filter:true,
            footer:true,
            function:getTotal,
          },
          {
            key:"margen",
            header: "Margin",
            width:70,
            type:tipoCelda.porcent,
            filter:true,
            footer:true,
            function:getMargenTotal
          },
        ]}
        data={ventas}
        
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setRutaRep: (ruta) => dispatch(setRutaRep(ruta)),
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
  setPropsRep: (props)=>dispatch({type:"SET_PROPS_REP",props:props})
});
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos
});

export default connect (mapStateToProps,mapDispatchToProps)(PanelReporteVentas);
