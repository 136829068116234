import React, { useEffect, useState } from 'react'
import { FechaLocalToUTC, FechaUTCToLocal, getArrayAsync, getSucursal } from '../../../services/functions';
import { connect } from 'react-redux';
import LoadingModal from '../../modal/LoadingModal';
import Modalgeneric from '../../modal/generic';
import Labelmoney from '../../labelmoney';

const ReporteFinancial=({config,setModal,setOpenModalCalendar,stateFechas,Categorias})=> {
  const [modalLoading, setmodalLoading] = useState({}),
  [fechas,setFechas]=useState(),
  [datos,setDatos] = useState({assets:0,cash:0,bank:0,inv:0,ar:0,arother:0,equip:0,
    apother:0,ap:0,equity:0,liabilities:0,
    sales:0,gprofit:0,ex:0,nprofit:0,equityBefore: 0,
    invI:0,buys:0,invF:0,invCost:0,nprofit1:0
  });
  const fechaI = '2023-01-01'/*FechaLocalToUTC(stateFechas.fecha_Inicial)*/,fechaF=FechaLocalToUTC(stateFechas.fecha_Final,23,59,59);

  const peticiones ={
    ar:{
      hashKey:{valor:("REPORTE#CLIENTES#"+getSucursal()),nombre:"id"},
      sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"type"},
    },
    ap:{
      hashKey:{valor:("REPORTE#PROVEEDORES#"+getSucursal()),nombre:"id"},
      sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"type"},
    },
    mov:{
      hashKey:{valor:("REPORTE#$#"+getSucursal()),nombre:"id"},
      sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"type"},
    },
    inv:{
      hashKey:{valor:"REP#LINEA#"+getSucursal(),nombre:"id"},
      sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"type"},
    },
    apothout:{
      hashKey:{valor:"APOTHOUT#"+getSucursal(),nombre:"id"},
      sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"type"},
    },
    apothin:{
      hashKey:{valor:"APOTHIN#"+getSucursal(),nombre:"id"},
      sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"type"},
    },
    arothout:{
      hashKey:{valor:"AROTHOUT#"+getSucursal(),nombre:"id"},
      sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"type"},
    },
    arothin:{
      hashKey:{valor:"AROTHIN#"+getSucursal(),nombre:"id"},
      sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"type"},
    },
    sales:{
      hashKey:{valor:"REPORTE#VENTA#"+getSucursal(),nombre:"id"},
      sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"type"},
    },
    buys:{
      indexName:"reverseIndex",
      hashKey:{valor:("COMPRA#"+getSucursal()),nombre:"type"},
      sortKey:{valor:[stateFechas.fecha_Inicial,fechaF],operador:"between",nombre:"id"}
    },
    traspaso:{
      indexName:"reverseIndex",
      hashKey:{valor:("TRASPASO#"+getSucursal()),nombre:"type"},
      sortKey:{valor:[stateFechas.fecha_Inicial,fechaF],operador:"between",nombre:"id"}
    },
    pes:{
      indexName:"reverseIndex",
      hashKey:{valor:("PE#"+getSucursal()),nombre:"type"},
      sortKey:{valor:[getSucursal()+'#'+FechaLocalToUTC(fechaI), getSucursal()+'#'+fechaF],operador:"between",nombre:"id"},
    },
    merma:{
      hashKey:{valor:"MERMA#"+getSucursal(),nombre:"id"},
      sortKey:{valor:[FechaLocalToUTC(fechaI),fechaF],operador:"between",nombre:"type"},
    },
    adjust:{
      hashKey:{valor:"AJUSTE#"+getSucursal(),nombre:"id"},
      sortKey:{valor:[FechaLocalToUTC(fechaI),fechaF],operador:"between",nombre:"type"},
    }

  };

  const closeModal=()=> {
    setFechas({...fechas,fecha_Inicial:stateFechas.fecha_Inicial,fecha_Final:stateFechas.fecha_Final});
    setModal({ isOpen: false });
  }
  const openModal=()=>{
    setModal({ isOpen: true });
  }

  const openmodalLoading = (e) => { setmodalLoading({ isOpen: true }); };
  const closemodalLoading = (e) => { setmodalLoading({ isOpen: false }); };

  useEffect(()=>{
    const obtenerPeticiones=async()=>{
      const newDatos={assets:0,cash:0,bank:0,inv:0,ar:0,arother:0,equip:0,
        apother:0,ap:0,equity:0,liabilities:0,
        sales:0,gprofit:0,ex:0,nprofit:0,equityBefore: 0,
        invI:0,buys:0,invF:0,invCost:0,nprofit1:0,buysPes:0,adjust:0
      };
      openmodalLoading();
      const ar = await getArrayAsync(peticiones.ar,[]),
      ap = await getArrayAsync(peticiones.ap,[]),
      mov = await getArrayAsync(peticiones.mov,[]),
      inventory = await getArrayAsync(peticiones.inv,[]),
      apothout = await getArrayAsync(peticiones.apothout,[]),
      apothin = await getArrayAsync(peticiones.apothin,[]),
      arothout = await getArrayAsync(peticiones.arothout,[]),
      arothin = await getArrayAsync(peticiones.arothin,[]),
      sales = await getArrayAsync(peticiones.sales,[]),
      compras = await getArrayAsync(peticiones.buys,[]),
      traspasos  = await getArrayAsync(peticiones.traspaso,[]),
      peses = await  getArrayAsync(peticiones.pes,[]);
      /*mermas =  await getArrayAsync(peticiones.merma,[]),
      ajuste = await getArrayAsync(peticiones.adjust,[]);*/

      
      ar.forEach(rep=>{
        const report = rep?.clientes;
        if(report){
          Object.keys(report).map(key=>{
            newDatos.ar += (report[key]?.ventas || 0) - ((report[key]?.pagos || 0) + (report[key]?.descuento || 0));
          })
        }
      })
      
      ap.forEach(rep=>{
        const report = rep?.proveedores;
        if(report){
          Object.keys(report).map(key=>{
            newDatos.ap += ((report[key]?.compras || 0) + (report[key]?.envio || 0)) - ((report[key]?.pagos || 0) + (report[key]?.descuento || 0) + (report[key]?.devolucion||0));
          })
        }
      })
      
      mov.forEach(rep=>{
        if(rep){
          Object.keys(rep).map(key=>{
            const cuenta = key=="CAJA"?"cash":"bank";

            newDatos[cuenta] += (rep[key].ingresos||0) -  (rep[key].egresos||0);
            newDatos.ex += rep[key]?.ex||0;
          })
        }
      })

      inventory.forEach(rep=>{
      const report = rep?.inventario;
      if(report){
        Object.keys(report).map(key=>{
          newDatos.inv += report[key]?.monto||0;
          newDatos.invI += rep.type < stateFechas.fecha_Inicial ? report[key]?.monto||0: 0;
        })
      }
    })

      apothout.forEach(rep=>{
        newDatos.apother -= Math.abs(rep?.importe||0);
      })

      apothin.forEach(rep=>{
        newDatos.apother += rep?.importe||0;
      })

      arothout.forEach(rep=>{
        newDatos.arother += Math.abs(rep?.importe||0);
      })

      arothin.forEach(rep=>{
        newDatos.arother -= rep?.importe||0;
      })
      
      sales.forEach(rep=>{
        newDatos.sales += rep?.totalVendido||0;
        newDatos.gprofit += rep?.utilidad||0;
      })

      compras.forEach(rep=>{
        newDatos.buys +=  rep.importe;
      })

      traspasos.forEach(rep=>{
        newDatos.buys -=  rep.importe;
      })
      const newPes = peses.filter(pes=>(!['CANCELADO'].includes(pes.status)));

      newPes.forEach(rep=>{
        const pv = rep?.importe||0, pc =rep?.precioCompra||0;

        newDatos.buysPes += rep?.precioCompra||0;
        newDatos.sales += pv;
        newDatos.gprofit += (pv-pc);
      })
      
      /*mermas.forEach(rep=>{
        newDatos.adjust += rep?.importeTotal||0;
      })
      newDatos.adjust = newDatos.adjust*-1;

      ajuste.forEach(rep=>{
        newDatos.adjust += (rep?.importeTotal||0)*-1;
      })*/

      newDatos.ex = newDatos.ex <0 ? newDatos.ex *-1: newDatos.ex;
      newDatos.assets = newDatos.cash + newDatos.inv + newDatos.ar + newDatos.arother + newDatos.bank + newDatos.equip;
      newDatos.liabilities = newDatos.apother + newDatos.ap;
      newDatos.equity =  newDatos.assets - newDatos.liabilities;
      newDatos.nprofit =  newDatos.equity - newDatos.equityBefore;
      //newDatos.gprofit -= newDatos.adjust;
      newDatos.nprofit1 = newDatos.gprofit - newDatos.ex;
      newDatos.invF = newDatos.inv;
      newDatos.invCost = (newDatos.invI+newDatos.buys)-newDatos.invF;
      newDatos.gprofit1 = newDatos.sales - (newDatos.invCost + newDatos.buysPes) ;
      
      setDatos(newDatos)
      closemodalLoading();
    };

   obtenerPeticiones();

  },[stateFechas.fecha_Inicial,stateFechas.fecha_Final])

  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargando Reporte</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

      <Modalgeneric w="size-w-40" h="size-h-90" front="z-10" config={config}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>{'Reporte Financiero del '+stateFechas.fecha_Inicial +' al '+stateFechas.fecha_Final }</Modalgeneric.Title>
          <div className='flex flex-col gap-3  items-center justify-center'>
            <div className="flex flex-col w-72 border border-black rounded-md">
                <div className='flex flex-wrap justify-between items-center border border-black border-t-0 border-r-0 border-l-0'>
                  <p className='font-semibold' title='Activos'>{"ASSETS"}</p>
                  <Labelmoney twoDecimal={true} className="w-36">{datos.assets}</Labelmoney>
                </div>
                <div className='flex flex-wrap justify-between items-center'>
                  <p className='' title='Efectivo'>{"CASH"}</p>
                  <Labelmoney twoDecimal={true} className="w-36">{datos.cash}</Labelmoney>
                </div>
                <div className='flex flex-wrap justify-between items-center'>
                  <p className='' title='Bancos'>{"BANK"}</p>
                  <Labelmoney twoDecimal={true} className="w-36">{datos.bank}</Labelmoney>
                </div>
                <div className='flex flex-wrap justify-between items-center'>
                  <p className='' title='Inventario'>{"INVENTORY"}</p>
                  <Labelmoney twoDecimal={true} className="w-36">{datos.inv}</Labelmoney>
                </div>
                <div className='flex flex-wrap justify-between items-center'>
                  <p className='' title='Clientes'>{"AR"}</p>
                  <Labelmoney twoDecimal={true} className="w-36">{datos.ar}</Labelmoney>
                </div>
                <div className='flex flex-wrap justify-between items-center'>
                  <p className='' title='Prestamo a Externos'>{"AROTHER"}</p>
                  <Labelmoney twoDecimal={true} className="w-36">{datos.arother}</Labelmoney>
                </div>
                <div className='flex flex-wrap justify-between items-center border border-black border-b-0 border-r-0 border-l-0'>
                  <p className='font-semibold' title='Equipo'>{"EQUIPMENT "}</p>
                  <Labelmoney twoDecimal={true} className="w-36">{datos.equip}</Labelmoney>
                </div>
            </div>

            <div className="flex flex-col w-72 border border-black rounded-md">
                <div className='flex flex-wrap justify-between items-center border border-black border-t-0 border-r-0 border-l-0'>
                  <p className='font-semibold' title='Deudas'>{"LIABILITIES"}</p>
                  <Labelmoney twoDecimal={true} className="w-36">{datos.liabilities}</Labelmoney>
                </div>
                <div className='flex flex-wrap justify-between items-center'>
                  <p className='' title='Inversionistas'>{"APOTHER"}</p>
                  <Labelmoney twoDecimal={true} className="w-36">{datos.apother}</Labelmoney>
                </div>
                <div className='flex flex-wrap justify-between items-center'>
                  <p className='' title='Proveedores'>{"AP"}</p>
                  <Labelmoney twoDecimal={true} className="w-36">{datos.ap}</Labelmoney>
                </div>
                <div className='flex flex-wrap justify-between items-center border border-black border-b-0 border-r-0 border-l-0 bg-sky-200 rounded-l-md'>
                  <p className='font-semibold' title='Capital (Assets - Liabilities)'>{"EQUITY"}</p>
                  <Labelmoney twoDecimal={true} className="w-36 ">{datos.assets-datos.liabilities}</Labelmoney>
                </div>
                <div className='flex flex-wrap justify-between items-center border border-black border-b-0 border-r-0 border-l-0 bg-yellow-200 rounded-l-md rounded-b-md'>
                  <p className='font-semibold' title='Ganancia Neta (Equity-EquityBefore)'>{"NET PROFIT"}</p>
                  <Labelmoney twoDecimal={true} className="w-36 ">{datos.nprofit}</Labelmoney>
                </div>
            </div>

            <div className="flex flex-col w-72 border border-black rounded-md ">
                <div className='flex flex-wrap justify-between items-center'>
                  <p className='' title='Ventas'>{"SALES"}</p>
                  <Labelmoney twoDecimal={true} className="w-36">{datos.sales}</Labelmoney>
                </div>
                <div className='flex flex-wrap justify-between items-center bg-green-200 rounded-l-md'>
                  <p className='' title='Ganancia Bruta'>{"GROSS PROFIT"}</p>
                  <Labelmoney twoDecimal={true} className="w-36 ">{datos.gprofit}</Labelmoney>
                </div>
                <div className='flex flex-wrap justify-between items-center'>
                  <p className='' title='Gastos'>{"EXPENSE"}</p>
                  <Labelmoney twoDecimal={true} className="w-36">{datos.ex}</Labelmoney>
                </div>
                <div className='flex flex-wrap justify-between items-center'>
                  <p className='' title='Mermas'>{"LOSS"}</p>
                  <Labelmoney twoDecimal={true} className="w-36">{datos.adjust}</Labelmoney>
                </div>

                <div className='flex flex-wrap justify-between items-center border border-black border-b-0 border-r-0 border-l-0 bg-yellow-200 rounded-l-md ronded-b-md'>
                  <p className='font-semibold' title='Ganancia Neta (GrossProfit-Ex)'>{"NET PROFIT"}</p>
                  <Labelmoney twoDecimal={true} className="w-36 ">{datos.nprofit1}</Labelmoney>
                </div>
            </div>

            <div className="flex flex-col w-72 border border-black rounded-md">
                <div className='flex flex-wrap justify-between items-center'>
                  <p className='' title='Inventario Inicial'>{"INV INITIAL"}</p>
                  <Labelmoney twoDecimal={true} className="w-36">{datos.invI}</Labelmoney>
                </div>
                <div className='flex flex-wrap justify-between items-center'>
                  <p className='' title='Compras'>{"PUCHARSES"}</p>
                  <Labelmoney twoDecimal={true} className="w-36">{datos.buys}</Labelmoney>
                </div>
                <div className='flex flex-wrap justify-between items-center'>
                  <p className='' title='Inventario Final'>{"INV FINAL"}</p>
                  <Labelmoney twoDecimal={true} className="w-36">{datos.invF}</Labelmoney>
                </div>
                <div className='flex flex-wrap justify-between items-center'>
                  <p className='' title='Costo Inventario (InvInitial+Pucharses-InvFinal)'>{"INV COST"}</p>
                  <Labelmoney twoDecimal={true} className="w-36">{datos.invCost}</Labelmoney>
                </div>
                
                <div className='flex flex-wrap justify-between items-center'>
                  <p className='' title='Pedidos Especiales'>{"BUYS PES"}</p>
                  <Labelmoney twoDecimal={true} className="w-36">{datos.buysPes}</Labelmoney>
                </div>
                <div className='flex flex-wrap justify-between items-center'>
                  <p className='' title='Ventas'>{"SALES"}</p>
                  <Labelmoney twoDecimal={true} className="w-36">{datos.sales}</Labelmoney>
                </div>

                <div className='flex flex-wrap justify-between items-center bg-green-200 rounded-l-md ronded-b-md'>
                  <p className='' title='Ganancia Bruta (Sales-InvCost)'>{"GROSS PROFIT"}</p>
                  <Labelmoney twoDecimal={true} className="w-36 ">{datos.gprofit1}</Labelmoney>
                </div>
            </div>

          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button className="btn-gray2" onClick={e=>{
            closeModal();
          }}>
            Cerrar
          </button>
          <button 
            className={"btn-blue"} 
            type="button"
            onClick={e=>{
              setOpenModalCalendar(true);
            }}>
              <i className='fas fa-calendar-day text-black-700'></i>
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>
    </>
  )
}
const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos,
});

export default connect (mapStateToProps,null)(ReporteFinancial)