import React, { useState } from 'react'
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { AddDiasFecha, FechaLocalToUTC, FechaUTCToLocal, Sucursal, TransformIndex, apiUsePromise, getArrayAsync, getSucursal, getTotal, shortIdToId, tipoCelda, validaFecha, valueToMoney2 } from '../../../services/functions';
import Modaldanger from '../../modal/danger';
import LoadingModal from '../../modal/LoadingModal';
import TableReactWindows from './TableReactWindows';
import { GenerateXlsx, SaveXlsx, columnasXlsx, formatoCelda } from '../../Excel/DataToXlsx';
import Modalsuccess from '../../modal/success';

const ReporteCash = ({PropsReportes,CuentaMayor,addDatos,Datos,setHeader}) => {
    const [modalLoading, setmodalLoading] = useState({}),
    [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
    [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
    [datos,setDatos]=useState([]);
    const nameComponent = "cashRango",rutaActualRep = PropsReportes.rutas[PropsReportes?.rutas?.length-1],
    fechaI = FechaLocalToUTC(PropsReportes.fecha_Inicial),fechaF=FechaLocalToUTC(PropsReportes.fecha_Final,23,59,59),
    cuenta = PropsReportes?.header?.filtro||"CAJA";
    let accounts =  Sucursal('cuentas');
    const datosComponent = Datos[nameComponent]||{};
    const repDayBefore = validaFecha(AddDiasFecha(PropsReportes.fecha_Inicial+"T23:59:59",-1),PropsReportes.fecha_Inicial).fechai;
    const rep_new={balanceBefore:0,concepto:"",clave:"",idCash:"0",fecha:"",monto:0,importe:0,in:0,out:0,cuenta:"",contracuenta:"",metodo:"",descripcion:"",fecharef:"-",balance:0};

    let peticiones =[
      {
        hashKey:{valor:("REPORTE#$#"+getSucursal()),nombre:"id"},
        sortKey:{valor:['2023-01-01',repDayBefore],operador:"between",nombre:"type"},
        //GetFechaActualSeparado().ano+
      },
      {
        indexName:"cuentaIndex",
        hashKey:{valor:(cuenta+"#"+getSucursal()),nombre:"cuenta"},
        sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"type"}
      }
    ]

    const openmodalLoading = (e) => {
        setmodalLoading({ isOpen: true });
      };
    const closemodalLoading = (e) => {
       setmodalLoading({ isOpen: false });
    };
    const openmodeldanger=(mensaje)=>{
    setmodaldangererrorConfig({
      isOpen: true,
      message: mensaje ||"error",
    });
    };
  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }
  const openModalSuccess=(message,titulo)=>{
    setmodalSuccessConfig({
      isOpen: true,
      title: titulo||"Solicitud Completa",
      message: message,        
    });
  }
  const closeModalsuccess=(e)=> {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }

  function SortVentas(vents) {
    const vt = vents.sort((a,b)=>{
      if (a.type > b.type) return 1;
      if (a.type < b.type) return -1;
      return 0;
    });
    
    return vt;
  }

  function CalculaBalance(reporte,balanceBefore){
    SortVentas(reporte);
    reporte.unshift(balanceBefore)
    reporte.map((item,index)=>{
      if(index>0){
        item.balance=(reporte[index-1]?.balance||0)+item.monto;
      }
    });
  }

  const getRep=async(repBefore,allRep,petLast)=>{
    if(petLast){ peticiones[0].LastEvaluatedKey = petLast;}

    const responseReportes= await apiUsePromise({ url: "/custom-query", method: "POST",data: peticiones[0]});
    const reportes = responseReportes.data.datos;

    reportes.forEach(rep=>{
      if(rep.type<PropsReportes.fecha_Inicial){
        //repBefore.in += rep[cuenta]?.ingresos||0;
        //repBefore.out += rep[cuenta]?.egresos||0;
        repBefore.balance += (rep[cuenta]?.ingresos||0)-(rep[cuenta]?.egresos||0);
        repBefore.importe += (rep[cuenta]?.ingresos||0)-(rep[cuenta]?.egresos||0);
      }
      allRep.push(rep);
    });
    
    if(responseReportes.data?.LastEvaluatedKey){
      await getRep(repBefore,allRep,responseReportes.data.LastEvaluatedKey);
    }
  }


  const getMovDetail=async(reporte,petLast)=>{
    if(petLast){ peticiones[1].LastEvaluatedKey = petLast;}
    const responseVentas= await apiUsePromise({ url: "/custom-query", method: "POST",data: peticiones[1]});
    const compras = responseVentas.data.datos;

    if(compras.length>0){
      compras.map((cash,index)=>{
        //console.log(cash)
        const newcash = {...cash};
        newcash.idCash = cash.id;
        newcash.fecha=FechaUTCToLocal(cash.type).fecha;
        newcash.metodo=cash.metodo,
        newcash.edit=false;
        newcash.monto =cash.importe;
        newcash.importe=(CuentaMayor[cash.clave]?.IO=="O"?cash.importe*-1:cash.importe);
        newcash.in = (CuentaMayor[cash.clave]?.IO=="I"?cash.importe:0);
        newcash.out =(CuentaMayor[cash.clave]?.IO=="O"?cash.importe*-1:0);
        newcash.cuenta =  TransformIndex(cash.cuenta);
        newcash.contracuenta =  TransformIndex(cash.contracuenta);
        newcash.metodo =  TransformIndex(cash.metodo);
        newcash.descripcion =  TransformIndex(cash.descripcion);
        newcash.balance =  0;
        newcash.fecharef = "-";
        try{
          let date = shortIdToId(newcash.descripcion) || "-";
          date<'2022-01-01'||date>'2100-01-01'?date = "-":null;
          if(!['-',null].includes(date)){
            const ref =  FechaUTCToLocal(date).fecha, id = FechaUTCToLocal(newcash.type).fecha;
            const result = [0].includes(ref.localeCompare(id))
            //result==true?console.log(ref,'<>',id,'==>',newcash.descripcion):null
            result==true?date="-":null;
            newcash.fecharef = date;
          }
        }catch (error){ null}

        delete newcash.id;
        reporte.push(newcash);
      })
    }

    if(responseVentas.data?.LastEvaluatedKey){ await getMovDetail(reporte,responseVentas.data.LastEvaluatedKey); }
  }


  const handleError = (error) => {
    openmodeldanger("No se pudo cargar los movimientos porfavor vuelve a cargar la pagina");
    closemodalLoading();
  }

  function getBalance(array,campo) {
    return array[array.length-1]?.[campo]||0
  }
  
  useEffect(()=>{
    if(PropsReportes.rutas.length!==1){return;}
    if(rutaActualRep!==nameComponent){return;}
    setHeader({text:"Cash detail",filtro:"CAJA"});
  },[PropsReportes.rutas]);

  useEffect(() => {
    if(PropsReportes.rutas.length!==1){return;}
    if(rutaActualRep!==nameComponent){return;}
    if(!cuenta){return}

    const getMovimientos=async()=>{
      openmodalLoading();
      try{
        let reporte=[],allRep=[],repBefore={...rep_new};
       
        await getRep(repBefore,allRep);
        repBefore.type= PropsReportes.fecha_Inicial+"T00:00:00.000000";
        repBefore.descripcion = "Beginning Balance";

        await getMovDetail(reporte);
        //console.log(reporte)
        
        CalculaBalance(reporte,repBefore);
        addDatos(nameComponent,[reporte,{fechai:PropsReportes.fecha_Inicial,fechaf:PropsReportes.fecha_Final,filtro:cuenta}]);
        setDatos(reporte);
        closemodalLoading();

      }catch(Error){
          closemodalLoading();
          handleError(Error);
      }
    }

    const getAllMovimientos =async()=>{
      openmodalLoading();
      const peticionCash = {
        indexName:"cuentaIndex",
        hashKey:{valor:("CAJA#"+getSucursal()),nombre:"cuenta"},
        sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"type"}
      };
      let peticionesBank = [],repBefore = {...rep_new},reporte=[];

      accounts.forEach(met=>{
        const key = Object.keys(met)[0];
        const value = met[key];
        const cuenta = key+"/"+value;
       peticionesBank.push({
          indexName:"cuentaIndex",
          hashKey:{valor:(cuenta+"#"+getSucursal()),nombre:"cuenta"},
          sortKey:{valor:[fechaI,fechaF],operador:"between",nombre:"type"}
        })
      })

      const respReport = await getArrayAsync(peticiones[0],[]);
      respReport.forEach(rep=>{
        if(rep.type<PropsReportes.fecha_Inicial){

          repBefore.balance += (rep.ingresos||0)-(rep.egresos||0);
          repBefore.importe += (rep.ingresos||0)-(rep.egresos||0);
        }
      })
      repBefore.type= PropsReportes.fecha_Inicial+"T00:00:00.000000";
      repBefore.descripcion = "Beginning Balance";

      const respBank = await Promise.all(peticionesBank.map(peticion=> getArrayAsync(peticion,[])));
      const respCash = await getArrayAsync(peticionCash,[]);
      

      respBank.forEach(cuentas=>{
        cuentas.forEach(cash=>{
          const newcash = {...cash};
          newcash.idCash = cash.id;
          newcash.fecha=FechaUTCToLocal(cash.type).fecha;
          newcash.metodo=cash.metodo,
          newcash.edit=false;
          newcash.monto =cash.importe;
          newcash.importe=(CuentaMayor[cash.clave]?.IO=="O"?cash.importe*-1:cash.importe);
          newcash.in = (CuentaMayor[cash.clave]?.IO=="I"?cash.importe:0);
          newcash.out =(CuentaMayor[cash.clave]?.IO=="O"?cash.importe*-1:0);
          newcash.cuenta =  TransformIndex(cash.cuenta);
          newcash.contracuenta =  TransformIndex(cash.contracuenta);
          newcash.metodo =  TransformIndex(cash.metodo);
          newcash.descripcion =  TransformIndex(cash.descripcion);
          newcash.balance =  0;

          delete newcash.id;
          reporte.push(newcash);
        })
      })

      respCash.forEach(cash=>{
        const newcash = {...cash};
          newcash.idCash = cash.id;
          newcash.fecha=FechaUTCToLocal(cash.type).fecha;
          newcash.metodo=cash.metodo,
          newcash.edit=false;
          newcash.monto =cash.importe;
          newcash.importe=(CuentaMayor[cash.clave]?.IO=="O"?cash.importe*-1:cash.importe);
          newcash.in = (CuentaMayor[cash.clave]?.IO=="I"?cash.importe:0);
          newcash.out =(CuentaMayor[cash.clave]?.IO=="O"?cash.importe*-1:0);
          newcash.cuenta =  TransformIndex(cash.cuenta);
          newcash.contracuenta =  TransformIndex(cash.contracuenta);
          newcash.metodo =  TransformIndex(cash.metodo);
          newcash.descripcion =  TransformIndex(cash.descripcion);
          newcash.balance =  0;

          delete newcash.id;
          reporte.push(newcash);
      })
      
      CalculaBalance(reporte,repBefore);
      addDatos(nameComponent,[reporte,{fechai:PropsReportes.fecha_Inicial,fechaf:PropsReportes.fecha_Final,filtro:"TODO"}]);
      setDatos(reporte);
      closemodalLoading();
    }

    if(JSON.stringify(datosComponent)=='{}'){
      getMovimientos();
    }else{
      if(PropsReportes.fecha_Inicial==datosComponent[1].fechai&&PropsReportes.fecha_Final==datosComponent[1].fechaf&&cuenta==datosComponent[1].filtro){
        setDatos(datosComponent[0]);
        return;
      }
      if(cuenta=="TODO"){
        getAllMovimientos();
      }else{ getMovimientos();}
    }
  }, [PropsReportes.fecha_Inicial,PropsReportes.fecha_Final,PropsReportes.rutas,cuenta]);

  return (
    <>
    <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargado Movimientos</LoadingModal.Title>
        </LoadingModal.Body>
    </LoadingModal>
    <Modaldanger config={modaldangererrorConfig}>
            <Modaldanger.Message title={"Error"}>
            {modaldangererrorConfig.message}
            </Modaldanger.Message>
            <Modaldanger.Buttons>
            <button
                type="button"
                className="btn-gray2 "
                onClick={closeModaldangererror}
            >
                Cerrar
            </button>
            </Modaldanger.Buttons>
    </Modaldanger>

    <Modalsuccess config={modalSuccessConfig} tab="200" closeModal={closeModalsuccess}>
        <Modalsuccess.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"200"}
            className={"btn-green"}
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>
    
    
    <TableReactWindows
      btnDownload={async(e,dat)=>{
        const data = dat||datos;
        if(!data?.length){handleError("No hay datos que exportar"); return;}
        openmodalLoading();
        const workb = await GenerateXlsx();
        if(workb?.error){
          closemodalLoading();
          handleError(workb.error);
          return
        }

        const nameFile="Movimientos-"+cuenta,
        headerFile="Reporte movimientos "+cuenta+" del "+PropsReportes.fecha_Inicial+" al "+PropsReportes.fecha_Final,
        sheet1 = workb.sheet(0),colPlus = 4;

        sheet1.cell("A1").value(headerFile);
        sheet1.cell("A1").style("bold",true);
        sheet1.cell("A1").style("fontSize",18);
    
        const sizeData = data.length + colPlus,
        header={'Fecha':13,'EX/CASH':10,'Monto':17,'MajorAccount':20,'MinorAccount':20,'Cuenta':20,'Entra':17,'Sale':17,'Balance':19,'Descripcion':50};
    
        Object.keys(header).map((key,index)=>{
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).value(key);
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).style("horizontalAlignment", "center");
          sheet1.cell(columnasXlsx[index]+(colPlus-1)).style({fill: {type: "solid",color: "01036C"},fontColor: "FFFFFF",bold: true});
          sheet1.column(columnasXlsx[index]).width(header[key]); 
        })

        data.forEach((venta,index) => {
          sheet1.cell(columnasXlsx[0]+(colPlus+index)).value(venta?.fecha||"");
          sheet1.cell(columnasXlsx[1]+(colPlus+index)).value(venta?.concepto||"");
          sheet1.cell(columnasXlsx[2]+(colPlus+index)).value(valueToMoney2(venta?.importe)||0);
          sheet1.cell(columnasXlsx[2]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[3]+(colPlus+index)).value(venta?.clave||"");
          sheet1.cell(columnasXlsx[4]+(colPlus+index)).value(venta?.contracuenta||"");
          sheet1.cell(columnasXlsx[5]+(colPlus+index)).value(venta?.cuenta||"");
          sheet1.cell(columnasXlsx[6]+(colPlus+index)).value(valueToMoney2(venta?.in)||0);
          sheet1.cell(columnasXlsx[6]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[7]+(colPlus+index)).value(valueToMoney2(venta?.out)||0);
          sheet1.cell(columnasXlsx[7]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[8]+(colPlus+index)).value(valueToMoney2(venta?.balance)||0);
          sheet1.cell(columnasXlsx[8]+(colPlus+index)).style(formatoCelda.contabilidad);
          sheet1.cell(columnasXlsx[9]+(colPlus+index)).value(venta?.descripcion||"");
        });
    
        sheet1.cell(columnasXlsx[6]+sizeData).formula("SUM("+columnasXlsx[6]+""+colPlus+":"+columnasXlsx[6]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[6]+sizeData).style(formatoCelda.contabilidad);
        sheet1.cell(columnasXlsx[7]+sizeData).formula("SUM("+columnasXlsx[7]+""+colPlus+":"+columnasXlsx[7]+""+(sizeData-1)+")");
        sheet1.cell(columnasXlsx[7]+sizeData).style(formatoCelda.contabilidad);
    
        
        const response =await SaveXlsx(workb,nameFile);
        if(response?.error){
          closemodalLoading();
          handleError(response.error);
          return
        }
        closemodalLoading();
        openModalSuccess("Archivo generado correctamente","Descarga Completa");
      }}
        columns={[
         {
            key:"type",
            header:"Date",
            width:85,
            type:tipoCelda.date,
            filter:true,
          },
          {
            key:"concepto",
            header:"Cat",
            width:60, 
            type: tipoCelda.text,
            className: "text-center",
            filter:true,
          },
          {
            key:"importe",
            header:"Amount",
            width:100,
            type:tipoCelda.money,
            filter:true,
          },
          {
            key:"clave",
            header:"Major",
            width:95,
            type: tipoCelda.text,
            filter:true,
          },
          {
            key:"contracuenta",
            header:"Minor",
            width:95,
            type:tipoCelda.text,
            filter:true,
          },
          {
            key:"metodo",
            header:"Metodo",
            width:80,
            type: tipoCelda.text,
            className:"text-center",
            filter:true,
          },
          {
            key:"cuenta",
            header:"Cuenta",
            width:110,
            type: tipoCelda.text,
          },
          {
            key:"in",
            header:"IN",
            width:110,
            type:tipoCelda.money,
            className:"text-blue-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"out",
            header:"OUT",
            width:110,
            type:tipoCelda.money,
            className:"text-pink-800",
            filter:true,
            footer:true,
            function: getTotal,
          },
          {
            key:"balance",
            header:"Balance",
            width:120,
            type: tipoCelda.money,
            footer:true,
            function:getBalance
          },
          {
            key:"descripcion",
            header:"Remark",
            width:300,
            type: tipoCelda.text,
            filter:true,
          }, 
          {
            key:"fecharef",
            header:"Date Ref",
            width:85,
            type:tipoCelda.date,
            filter:true,
          },
        ]}
        data={datos}
      />
    </>
  )
};

const mapDispatchToProps = (dispatch) => ({
  addDatos:(nombre,datos)=>dispatch({type:"ADD_DATOS",nombre:nombre,datos:datos}),
  setHeader:(header)=> dispatch({type:"SET_HEADER_REP",header:header}),
});
const mapStateToProps = (state) => ({
    PropsReportes: state.PropsReportes,
    CuentaMayor:state.Catalogos.MAJORACCOUNTS,
    Datos: state.Datos
});

export default connect(mapStateToProps, mapDispatchToProps)(ReporteCash)