import React, { useEffect, useRef, useState } from 'react'
import { useCustomQuery, useGetProduct, useGetVenta, useInsertMerma, useInsertVenta } from '../../Hooks/useApis';
import { AddDiasFecha, FechaLocalToUTC, FechaUTCToLocal, GetFechaActual, getIdUser, getNombre, getSucursal, havePermission, moneyToValue, RestaFechas, showMoney, TransformSys, TransformSysAll } from '../../services/functions';
import LabelFecha from '../LabelFecha';
import Modalgeneric from '../modal/generic';
import TableModal from '../modal/TableModal';
import InputPrecioPanel from '../InputPrecioPanel'
import { connect } from 'react-redux';
import { setRuta } from '../../redux/Actions';
import Selectormap from '../Productos/Selectormap';
import Modalinfo from '../modal/info';
import Modalsuccess from '../modal/success';
import LoadingModal from '../modal/LoadingModal';
import InputProduct from '../inputProduct';
import Labelmoney from '../labelmoney';
import { permisos } from '../../static/dat/datos';

const BuscaTicket=({setRuta,SetTicket,Motivos,windowSize,itemsVenta,setItemsVenta,Categorias})=> {
    const [modalgenericonfig, setmodalgenericonfig] = useState({}),
    [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
    [modalItem, setmodalItem] = useState({}),
    [modalinfoConfig, setmodalinfoConfig] = useState({}),
    [tickets, setTickets] = useState([]),
    [errors,setErrors]=useState(''),
    [fecha,setFecha]=useState({inicial:GetFechaActual().fecha,final:GetFechaActual().fecha}),
    [importe,setImporte]=useState({inicial:0,final:0}),
    [fechaMin,setFechaMin]=useState(),
    [Ticket,setTicketSelect]=useState({}),
    [enableGarantia,setEnableGarantia]=useState(false),
    [enableCambio,setEnableCambio]=useState(false),
    [usuarios,setUsuario]=useState([]),
    [modalLoading, setmodalLoading] = useState({}),
    [garantia,setGarantia]=useState({responsable:"",ticket:"",descripcion:"",items:[],referencia:""}),
    [enable,setEnable]=useState(false);
    const permisoMermas = havePermission(permisos.regMerma);

    const inputCliente = useRef(),title = useRef("Sin Datos"),inputTicket=useRef("");

     const addItem=(new_item)=>{
      let new_items = [],find=false;
      garantia.items.forEach(function (item) {
        if (item.id === new_item.id) {
          new_items.push({...item,});
          find = true;
        } else {
          new_items.push(item);
        }
      });
  
      if (find) {
        setGarantia({...garantia,items:new_items});
      } else {
        setGarantia({...garantia,items:[...new_items, { ...new_item,cantidadVendida:1,qtyMax:new_item.cantidadVendida},]});
      }
    }

    function changecantidad(new_item, e) {
      const cantidad = parseInt(e);
      let new_items = [...garantia.items];
      setGarantia({...garantia,items:new_items.map((item) =>
          item.id === new_item.id
          ?{ ...item, cantidadVendida : cantidad,importe:cantidad*item.precioCompra}
          :item
      )})
    }
  
    const openmodalLoading = (titulo) => {setmodalLoading({ isOpen: true, titulo:titulo });};
    const closemodalLoading = (e) => {setmodalLoading({ isOpen: false }); };
    const openmodalgeneric = (e) => { setmodalgenericonfig({ isOpen: true }); };
    const closemodalgeneric = (e) => { setmodalgenericonfig({ isOpen: false }); };
    const openmodalItem = (e) => { setmodalItem({ isOpen: true }); };
    const closemodalItem = (e) => { setmodalItem({ isOpen: false }); };

    function closeModalinfo(e) { setmodalinfoConfig({ isOpen: false }); }
    function closeModalsuccess(e) {
      const newdataconfig = { ...modalSuccessConfig };
      newdataconfig.isOpen = false;
      setmodalSuccessConfig(newdataconfig);
      closemodalItem();
      closemodalgeneric();
      setGarantia({responsable:"",ticket:"",descripcion:"",items:[],referencia:""});
      setEnableGarantia(false);
    }

    const funcionError=(error)=>{
        closemodalLoading();
        setEnable(false);
    }
    const funcionExito =(respuesta)=>{
      closemodalLoading();
      setEnable(false);
      if(!respuesta.datos.length){setErrors('No hay tickets');setTickets([]);return;}
      setErrors('')
      let newVentas =[];
      respuesta.datos.forEach(venta=>{
        const newVenta = venta;
        let pagado = 0;
        newVenta.pagos.forEach(pago => {   pagado += pago?.excedente||pago.monto; });
        if(pagado-newVenta.total>0){ newVenta.cambio = pagado-newVenta.total;  }
        newVentas.push(newVenta);
      })
      console.log(newVentas)
      setTickets(newVentas);
    }


    const funcionExitoVenta=async(respuesta)=>{
      const prods = respuesta.items;
      const newVenta = {...respuesta.venta};
      let items = [];
      if(prods.length>0){
        prods.map(item=>{
          if(item.id=="VENTA#SERVICE"){
            item.servicios.map(serv=>{
              items.push(serv)
            })
          }else{
            items.push(item);
          }
          
        });
        let pagado = 0;
        respuesta.venta.pagos.forEach(pago => {   pagado += pago?.excedente||pago.monto; });
        if(pagado-newVenta.total>0){ newVenta.cambio = pagado-newVenta.total;  }

        setTicketSelect({items:items,venta:newVenta})
        if(!permisoMermas){return;}
        setGarantia({...garantia,ticket:newVenta.shortId,items:[],referencia:""});
      }
    }
    const handleSuccessful=(data)=>{
      const personas = data.datos
      let usuarios = {};
        if(personas.length==0){return;}
        personas.map(personal=>usuarios[personal?.id]=personal?.nombre)
        setUsuario(usuarios)
    }
    const handleError = (error) => { setmodalinfoConfig({ isOpen: true, message: error?.response?.data?.message,}); }
    const funcionExitoMerma=(respuesta)=>{
        setmodalSuccessConfig({
          isOpen: true,
          message: respuesta,
          title: "AJUSTE REALIZADO CON EXITO"
        }); 
    }
    const QueryTickets=()=>{
      setEnable(true);
      setEnableGarantia(false);
      setTickets([]);
      setTicketSelect({});
      setEnableCambio(false);
      setGarantia({...garantia,descripcion:""});
      
      if(inputTicket?.current?.value){
        const tk = inputTicket.current.value.trim().toUpperCase();
        openmodalLoading();
        useGetVenta(tk,"",resp=>{
          closemodalLoading();
          if(resp?.venta){
            const newVenta = resp.venta;
            setErrors('')
            let pagado = 0;
            newVenta.pagos.forEach(pago => {   pagado += pago?.excedente||pago.monto; });
            if(pagado-newVenta.total>0){ newVenta.cambio = pagado-newVenta.total;  }

            setTickets([newVenta]);
          }
          setEnable(false);
        },funcionError)
        return;
      }
      let filtros = [{valor:'',operador:'exists',nombre:'pagado'}];
      let fechaI=fecha.inicial,fechaF=fecha.final;
      
      if(RestaFechas(fechaI,fechaF)<0){
        fechaI= fecha.inicial;
        fechaF=fecha.final;
      }
    
      if(inputCliente.current.value==""){
        if(RestaFechas(fechaI,fechaF)>61){
          setErrors('El rango de fechas no puede ser mayor a 60 dias,cuando no se especifica el cliente');
          setEnable(false);
          return;
        }
      }
      if(importe.inicial>0||importe.final>0){
        let importeI=importe.inicial,importeF=importe.final;
        if(importeI>importeF){
          importeI = importe.final;
          importeF =  importe.inicial;
        }
        filtros.push({valor:[moneyToValue(importeI),moneyToValue(importeF)],operador:'between',nombre:'total'})
      }
          
      openmodalLoading();
      const peticion ={
          indexName:"reverseIndex",
          hashKey:{valor:("VENTA#"+getSucursal()),nombre:"type"},
          sortKey:{valor:[FechaLocalToUTC(fechaI),FechaLocalToUTC(fechaF,23,59,59)],operador:"between",nombre:"id"},
          filters:filtros
      }
      if(inputCliente.current.value!==""){
        peticion.indexName="asociadoIndex";
        peticion.hashKey ={valor:("VENTA#"+getSucursal())+"#"+(inputCliente.current.value.toUpperCase()=="SYS"?inputCliente.current.value.toUpperCase()+"#"+getSucursal():inputCliente.current.value.toUpperCase()),nombre:"sujetoAsociado"};
        peticion.sortKey ={valor:[FechaLocalToUTC(fechaI),FechaLocalToUTC(fechaF,23,59,59)],operador:"between",nombre:"id"};
      }
      useCustomQuery(peticion,funcionExito,funcionError);
    }
    
    useEffect(()=>{
        if(!fecha.final){return;}
        setFechaMin(AddDiasFecha(fecha.final,-30));
    },[fecha.final])

    useEffect(()=>{
      if(garantia.items.length==0){return}
      let new_garantias={...garantia};
      itemsVenta.forEach(item=>{
        let exist = new_garantias.items.filter(gar=>(gar.id==item.id));
        if(exist.length==0){
          const new_item = {...item};
          const propSucursales = item.sucursales?.[getSucursal()];
          new_item.cantidadVendida = item.cantidad;
          new_item.precioCompra = propSucursales.precioCompra;
          
          new_garantias.items.push(new_item);
        }
      });

      setGarantia(new_garantias);
    },[itemsVenta])

    useEffect(()=>{
      const peticion ={
        indexName:"reverseIndex",
        hashKey:{valor:("USER"),nombre:"type"},
        filters:[{valor:getSucursal(),operador:'eq',nombre:'sucursal'}]
      }
      useCustomQuery(peticion,handleSuccessful,handleError);

    },[])

  return (
      <>
      <LoadingModal config={modalLoading}  >
        <LoadingModal.Body>
          <LoadingModal.Title>{modalLoading.titulo||"CARGANDO"}</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>
        <Modalinfo config={modalinfoConfig}>
          <Modalinfo.Message title={title.current}>
            {modalinfoConfig.message}
          </Modalinfo.Message>
          <Modalinfo.Buttons>
            <button
              type="button"
              className="btn-blue1"
              onClick={closeModalinfo}
            >
              Cerrar
            </button>
          </Modalinfo.Buttons>
        </Modalinfo>

        <Modalsuccess config={modalSuccessConfig} >
        <Modalsuccess.Body title={modalSuccessConfig.title}>
          Garantia Realizada Con Exito
        </Modalsuccess.Body>
        <Modalsuccess.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>

       <Modalgeneric config={modalItem} front={"z-30"} >
        <Modalgeneric.Body >
          <div className="flex flex-col w-full justify-center">
            <div className={enableCambio?"w-full size-3-none items-center justify-center":"hidden"}>
              <label className=" text-size font-semibold">Articulo: </label>
              <InputProduct Ruta="Cambio" enableInput={(enableCambio&&JSON.stringify(Ticket)!=="{}"?true:false)}/>
            </div>
            <TableModal
                searchinput={false}
                columns={[
                  { header: "Codigo",
                  // eslint-disable-next-line react/display-name
                  code: (item) => (
                  <div className='size-form'>
                    <button 
                    className='text-red-600 rounded-full hover:text-red-500 hover:scale-110 transform mr-1'
                    onClick={e=>{
                      let itemsGarantia = [...garantia.items];
                      itemsGarantia = itemsGarantia.filter(gar=>gar.id!==item.id);
                      setGarantia({...garantia,items:itemsGarantia});

                      let itemsV = [...itemsVenta];
                      itemsV = itemsV.filter(it=>it.id!==item.id);
                      setItemsVenta(itemsV);
                    }}>
                      <i className='fas fa-minus-circle bg-white rounded-full'></i>
                    </button>
                    <label className='text-size' >{item?.id||""}</label>
                  </div>)
                },
                { header: "Descripcion", width:"w-62", accessor: "descripcion"},
                { header: "Qty", 
                  width:"w-20",
                  // eslint-disable-next-line react/display-name
                  code: (item) => (<InputPrecioPanel
                    width="w-full"
                    form={true}
                    min={10000}
                    max={moneyToValue(item?.qtyMax)}
                    noShowSignal={true}
                    newValor={10000}
                    setValor={(e)=>{
                      changecantidad(item,e);
                    }}
                  />),
                },
                { header: "Costo",
                  // eslint-disable-next-line react/display-name
                  code: (item) => <Labelmoney twoDecimal={true}>{item.precioCompra}</Labelmoney>,
                },
                {
                  header: "Total",
                // eslint-disable-next-line react/display-name
                  code: (item) => <Labelmoney twoDecimal={true}>{item.importe}</Labelmoney>,
                },
                ]}
                footer={[
                  {},{},{},{},
                  {
                    width: "w-24",
                    // eslint-disable-next-line react/display-name
                    code:  <Labelmoney twoDecimal={true} className="font-semibold text-center">{garantia.items.reduce((total,item)=> parseInt(total)+parseInt(item.importe),0)} </Labelmoney>
                  },{}
                  ]}                
                data={garantia.items}
            />
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons >
          <button
            type="button"
            className={enableGarantia?"btn-dark bg-yellow-500 hover:bg-yellow-400":"hidden"}
            disabled={(garantia.items.length>0?false:true)}
            onClick={(e) => {
              title.current = "Campo Obligatorio";
              if(!garantia.responsable){setmodalinfoConfig({ isOpen: true, message: "El campo Responsable es obligatorio",}); return;}
              if(!garantia.descripcion){setmodalinfoConfig({ isOpen: true, message: "El campo Motivo es obligatorio",}); return;}
              if(garantia.descripcion=="OTRO"&&!garantia.referencia){setmodalinfoConfig({ isOpen: true, message: "El campo Motivo lleva un Referencia",}); return;}
              let items = [],cantTotal=0,importeTotal=0,noSendPetition=false;
              garantia.items.map(item=>{
                importeTotal += item.importe*-1;
                cantTotal += item.cantidadVendida*-1;

                items.push({
                id:item.id,
                cantidad: item.cantidadVendida*-1,
                importe: item.importe*-1,
                descripcion:item.descripcion,
                marca:item.marca.split('#')[0]||"",
                linea:item.linea,
                categoria: Categorias[item.id.substring(0,2)],
                responsable:garantia.responsable,
                ticket:garantia.ticket,
                })
              })
              //console.log('====>>>',items)
              useInsertMerma({items:items,descripcion:(garantia?.referencia!==""?garantia.referencia:garantia.descripcion),cantidadTotal:cantTotal,importeTotal:importeTotal,tipo:"MERMA"},funcionExitoMerma,funcionError);
            }}
          >
            Aplicar Garantia
          </button>
          <button
            type="button"
            className="btn-green mx-1"
            onClick={e=>{
              let sale={};
              const tk =Ticket.venta;
              const gar = garantia.items;
              let cantTotal = 0;
              gar.forEach(item=>{
                if(item?.devolucion){
                  cantTotal -= item.cantidadVendida;
                }else{
                  cantTotal+= item.cantidadVendida;
                }
              })
              //console.log(tk)
              //console.log('items',gar)

              sale={
                "sucursal": getSucursal(),
                "cliente": TransformSysAll(tk.cliente),
                "vendedor": getIdUser(),
                "nombreVendedor": getNombre(),
                "nombreCliente": tk.nombreCliente,
                //productos que voy acomprar
                "items":  gar.filter(item=>(!item?.devolucion))
                ,
                //productos que voy a devolver
                "devoluciones":gar.filter(item=>(item?.devolucion))
                ,
                "iva": 0,
                "numeroArticulos": cantTotal,
                "total": 0,
                "descuentoTotal": 0,
                "subtotal": 0,
                "utilidad": 0,
                "timezone": "America/Mexico_City",
            }
            //console.log(sale)
              useInsertVenta(sale,resp=>{console.log(resp)},error=>{console.log(error)});
            }}
          >
            Realizar Cambio
          </button>
          <button
            type="button"
            className="btn-blue mx-1"
            onClick={closemodalItem}
          >
            Agregar Mas
          </button>

        </Modalgeneric.Buttons>
        </Modalgeneric>


        <Modalgeneric front="z-20" w="w-full" h="h-full" config={modalgenericonfig} >
        <Modalgeneric.Body >
          <div className={"size-3 w-full justify-center h-full overflow-y-auto"}>
            <div className='flex flex-col gap-2 w-full h-full justify-start border border-gray-200 bg-gray-200' >
                <label type="text" disabled={true} className='w-full text-red-400 text-sm font-semibold text-center break-words' >{errors}</label>
                <div className='size-3 items-center'>
                    <label className=" font-semibold">Fecha I:</label>
                    <input 
                    className='border-form w-full md:col-span-2' 
                    type='date'
                    
                    max={fecha.final}
                    defaultValue={fecha.inicial}
                    onBlur={e=>{
                      setFecha({...fecha,inicial:e.target.value})
                    }}
                    ></input>
                </div>
                <div className='size-3 items-center'>
                    <label className='font-semibold'>Fecha F:</label>
                    <input 
                    className='border-form w-full md:col-span-2' 
                    type='date'
                    max={GetFechaActual().fecha}
                    onBlur={e=>{
                      setFecha({...fecha,final:e.target.value})
                    }}
                    defaultValue={fecha.final}
                    ></input>
                </div>
                <div className='w-full rounded-lg border-gray-500 py-1 border-dashed border flex flex-col gap-2'>
                  <div className='size-3 items-center'>
                      <label className=' font-semibold'>{"Importe Mayor:"}</label>
                      <InputPrecioPanel
                      min={0}
                      form={true}
                      className={"bg-white md:col-span-2"}
                      setValor={(e)=>{setImporte({...importe,inicial:parseFloat(e)});}}
                      />
                  </div>
                  <div className='size-3 items-center'>
                      <label className='font-semibold'>{"Importe Menor:"}</label>
                      <InputPrecioPanel
                      min={0}
                      form={true}
                      className={"bg-white md:col-span-2"}
                      setValor={(e)=>{setImporte({...importe,final:parseFloat(e)});}}
                      />
                  </div>
                </div>
                <div className='size-3  items-center'>
                    <label className='md:pt-2 nd:mr-1 font-semibold'>Cliente:</label>
                    <input className='border-form md:col-span-2 uppercase' type='text'  ref={inputCliente}></input>
                </div>
                <div className='size-3  items-center'>
                    <label className='md:pt-2 nd:mr-1 font-semibold'>Ticket:</label>
                    <input className='border-form md:col-span-2 uppercase' type='text' onKeyDown={e=>{if(e.key=="Enter"){QueryTickets();}}} ref={inputTicket}></input>
                </div>
                <div className='size-3 w-full gap-2 justify-center pt-2'>
                    <button 
                    className='btn-green w-full items-center'
                    disabled={enable}
                    type="button"
                    onClick={e=>{
                      QueryTickets();
                    }}
                    >Buscar</button>

                  <button
                  type='button' 
                    className={JSON.stringify(Ticket)!=="{}"?'btn-dark w-full  bg-yellow-500 hover:bg-yellow-400':"hidden"}
                    onClick={e=>{
                      setEnableGarantia(!enableGarantia);
                      setEnableCambio(false);
                    }}
                    >Garantia
                  </button>

                  <button
                  type='button' 
                    className={JSON.stringify(Ticket)!=="{}"?'btn-dark w-full  bg-bluei hover:bg-blue-900':"hidden"}
                    onClick={e=>{
                      setEnableCambio(!enableCambio);
                      setEnableGarantia(false);
                    }}
                    >Cambio
                  </button>
              </div>
              <div className={(permisoMermas&&enableGarantia&&JSON.stringify(Ticket)!=="{}"?'flex flex-col gap-1 w-full py-1 justify-center rounded-lg bg-sky-100 border-gray-500 border-dashed border':"hidden")}>
                <div className="w-full size-3-none items-center justify-center">
                  <label className=" text-size font-semibold">Personal: </label>
                  <Selectormap 
                  width={"w-full col-span-2"}
                  catalogo={usuarios}
                  getvalor={(valor) => {
                    if(!valor?.clave){setGarantia({...garantia,responsable:""});return;}
                    setGarantia({...garantia,responsable:valor.clave})
                  }}/>
                </div>
                <div className="w-full size-3-none items-center justify-center">
                  <label className=" text-size font-semibold">Motivo: </label>
                  <Selectormap 
                  width={"w-full col-span-2"}
                  catalogo={Motivos}
                  getvalor={(valor) => {
                    if(!valor?.clave){setGarantia({...garantia,descripcion:""});return;}
                    setGarantia({...garantia,descripcion:valor.valor})
                  }}/>
                </div>
                <div className={(garantia?.descripcion=="OTRO"?"w-full size-3-none items-center justify-center ":"hidden")}>
                  <label className="text-size font-semibold">Referencia: </label>
                  <textarea
                  className="border-form uppercase resize-none w-full col-span-2"
                  rows="2"
                  value={garantia.referencia}
                  onChange={(e) => {
                    setGarantia({...garantia,referencia:e.target.value.toUpperCase().trim()})
                  }}
                  ></textarea>
                </div>
                <div className={("w-full size-3-none items-center justify-center ")}>
                  <label className="text-size font-semibold">Ticket: </label>
                  <label className='font-bold w-full text-center col-span-2'>{Ticket?.venta?.shortId}</label>
                </div>
              </div>

              <div className={(permisoMermas&&enableCambio&&JSON.stringify(Ticket)!=="{}"?'flex flex-col gap-1 w-full py-1 justify-center rounded-lg bg-sky-100 border-gray-500 border-dashed border':"hidden")}>
                <div className={(garantia?.descripcion=="OTRO"?"w-full size-3-none items-center justify-center ":"hidden")}>
                  <label className="text-size font-semibold">Referencia: </label>
                  <textarea
                  className="border-form uppercase resize-none w-full col-span-2"
                  rows="2"
                  value={garantia.referencia}
                  onChange={(e) => {
                    setGarantia({...garantia,referencia:e.target.value.toUpperCase().trim()})
                  }}
                  ></textarea>
                </div>
                <div className={("w-full size-3-none items-center justify-center ")}>
                  <label className="text-size font-semibold">Ticket: </label>
                  <label className='font-bold w-full text-center col-span-2'>{Ticket?.venta?.shortId}</label>
                </div>
              </div>
            </div>
            <div className='w-full h-full md:col-span-2 size-1'>
              <div className='w-full h-2/6 '>
                <TableModal
                searchinput={false}
                columns={[
                    { header: "Venta",width:"w-16", accessor: "shortId" },
                    { header: "Cliente",width:"w-28", accessor: "cliente"},
                    { header: "Fecha", 
                      width:"w-16",
                      // eslint-disable-next-line react/display-name
                      code: (item) => (<LabelFecha >{item?.id||""}</LabelFecha>),
                    },
                    { header: "Vendedor",width:"w-28", accessor: "nombreVendedor" },
                    {
                      header: "Total",
                      width:"w-24",
                    // eslint-disable-next-line react/display-name
                      code: (item) => <Labelmoney twoDecimal={true} >{item.total}</Labelmoney>,
                    },
                    { header: "Vence", 
                      width:"w-24",
                      // eslint-disable-next-line react/display-name
                      code: (item) => ((item?.vencimiento?<LabelFecha hour={true}>{item.vencimiento}</LabelFecha>:null)),
                    },
                    ]}
                footer={[
                  {},{},{}, {
                    code:<p className="w-full text-right ">Totales:</p>
                  },
                  {
                    width: "w-24  ",
                    // eslint-disable-next-line react/display-name
                    code:  <Labelmoney font=" text-right" twoDecimal={true}>{tickets.reduce((total,item)=> parseInt(total)+parseInt(item.total),0)}</Labelmoney>
                  },{}
                  ]}
                onclickRow={(item,e) => {
                  if(e.detail==2||e.key=="Enter"){
                    useGetVenta(item.id,"",funcionExitoVenta,funcionError);
                  }else if(e.detail==1){
                    setTicketSelect({});
                    setEnableCambio(false);
                    setGarantia({...garantia,descripcion:""});
                  }
                }}
                data={tickets}
                />
              </div>
              <div className='w-full h-3/6'>
                <div className={(enableGarantia||enableCambio?"hidden":'size-form w-full overflow-y-auto items-center justify-center h-full border border-gray-500')}>
                  {(JSON.stringify(Ticket)!=="{}")?
                  <div className='w-full h-full  text-xs lg:text-sm'>
                    <p className="uppercase">Ticket: {Ticket?.venta?.shortId} </p>
                    <p className="uppercase">{"CLIENTE: "+TransformSys(Ticket?.venta?.cliente)}  {" "+Ticket?.venta?.nombreCliente || ""} </p>
                    <p className="uppercase">{"VENDEDOR: "+Ticket?.venta?.nombreVendedor}</p>
                    <table className="w-full text-2xs lg:text-xs font-semibold pt-1">
                      <thead>
                        <tr>
                          <th className="text-center">Cant</th>
                          <th className="text-start">Articulo</th>
                          <th className="text-start">Descripción</th>
                          <th className="text-center">Precio</th>
                          <th className="text-center">Descuento</th>
                          <th className="text-center">Subtotal</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Ticket?.items
                          ? Ticket.items.map((item, index) => {
                              return (
                              <tr key={index}>
                                <td className=" text-center">{item.cantidadVendida}</td>
                                <td className=" text-start text-2xs md:text-xs"> {item.id} </td>
                                <td className=" text-start text-2xs md:text-xs">{item.descripcion}</td>
                                <td className=" text-center ">{showMoney(item.precioVenta)}</td>
                                <td className=" text-center ">{item.descuento + "%"}</td>
                                <td className=" text-center ">{showMoney(item.importe)}</td>
                              </tr>)
                            })
                          : null}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td className="text-center"> {Ticket?.venta?.numeroArticulos || ""}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="grid grid-cols-2 my-2 w-full">
                      <div className=" w-full md:w-1/2 text-xs">
                        <div className="grid grid-cols-3">
                          <label>SubTotal:</label><Labelmoney className="w-20" twoDecimal={true}>{Ticket?.venta?.subtotal || 0}</Labelmoney>
                        </div>
                        {Ticket?.venta?.subtotal - Ticket?.venta?.total>0?
                        <div className="grid grid-cols-3">
                          <label>Descuento:</label><Labelmoney className="w-20" twoDecimal={true}>{(Ticket?.venta?.subtotal - Ticket?.venta?.total) || 0}</Labelmoney>
                        </div>
                        :null}
                        <div className="grid grid-cols-3">
                          <label>Importe:</label><Labelmoney className="w-20" twoDecimal={true}>{Ticket?.venta?.total || 0}</Labelmoney>
                        </div>
                      </div>

                      {Ticket?.venta?.pagos.length?
                      <div className="w-full md:w-1/2">
                        <div className="font-bold  ml-4">PAGOS</div>
                        
                        {Ticket.venta.pagos.map((pago,index)=>(
                          <div key={index} className="grid grid-cols-3 text-xs">
                            <label>{pago.metodo+":"}</label>
                            <Labelmoney className="w-20" twoDecimal={true}>{(pago?.excedente?pago.excedente:(pago.monto || 0))}</Labelmoney>
                            <label  >{FechaUTCToLocal(pago.fecha).fecha} {FechaUTCToLocal(pago.fecha).hora}</label>
                          </div>
                        ))}
                        <div className="grid grid-cols-3 text-xs ">
                          <label className="">Pagado:</label><Labelmoney className="w-20 " twoDecimal={true}>{Ticket?.venta?.pagado}</Labelmoney><span></span>
                        </div>
                        {Ticket?.venta?.total-Ticket?.venta?.pagado>0?
                        <div className="grid grid-cols-3 text-xs">
                          <label className={""}>Restante:</label><Labelmoney className="w-20 " twoDecimal={true}>{Ticket?.venta?.total-Ticket?.venta?.pagado || 0}</Labelmoney><span></span>
                        </div>
                        :null}
                        {Ticket?.venta?.cambio?
                        <div className="grid grid-cols-3 text-xs">
                           <label>Cambio:</label><Labelmoney className={"w-20"} twoDecimal={true}>{Ticket.venta?.cambio || 0}</Labelmoney><span></span>
                        </div>
                        :null}
                      </div>
                      :""
                      }
                      </div>
                    </div>
                    :""}
                </div>
                <div className={(!enableGarantia?"hidden":'w-full h-full')}>
                  {(JSON.stringify(Ticket)!=="{}")?
                  <div className='w-full h-full border border-gray-500 text-xs lg:text-sm overflow-y-auto'>
                    <TableModal
                    searchinput={false}
                    columns={[
                        { header: "Codigo",
                          // eslint-disable-next-line react/display-name
                          code: (item) => (
                          <div className='size-form'>
                            <button 
                            className={('text-blue-600 rounded-full hover:text-sky-500 hover:scale-110 transform mr-1')}
                            hidden={(item?.id?(item.id.substring(0,2)=="SE"?true:false):false)}
                            onClick={e=>{
                              let itemsGarantia = [...garantia.items];
                              itemsGarantia.push(item);
                              addItem(item);
                              openmodalItem();
                            }}>
                              <i className='fas fa-plus-circle bg-white rounded-full'></i>
                            </button>
                            <label className='text-size' >{item?.id||""}</label>
                          </div>)
                        },
                        { header: "Descripcion", accessor: "descripcion"},
                        { header: "Qty", 
                          width:"w-20",
                          // eslint-disable-next-line react/display-name
                          code: (item) => (<InputPrecioPanel
                            width="w-full"
                            min={0}
                            noShowSignal={true}
                            readOnly={true}
                            disabled={true}
                            newValor={moneyToValue(item?.cantidadVendida)}
                            setValor={(e)=>{}}
                          />),
                        },
                        { header: "Precio U",
                          // eslint-disable-next-line react/display-name
                          code: (item) => <Labelmoney twoDecimal={true}>{item.precioVenta}</Labelmoney>,
                        },
                        { header: "Desc %",
                          // eslint-disable-next-line react/display-name
                          code: (item) => <Labelmoney twoDecimal={true}>{item.precioVenta}</Labelmoney>,
                        },
                        {
                          header: "Total",
                        // eslint-disable-next-line react/display-name
                          code: (item) => <Labelmoney twoDecimal={true}>{item.importe}</Labelmoney>,
                        },
                        ]}
                    footer={[
                      {},{},{},{},
                      {
                        width: "w-24",
                        // eslint-disable-next-line react/display-name
                        code:  <Labelmoney twoDecimal={true} className="font-semibold text-center">{tickets.reduce((total,item)=> parseInt(total)+parseInt(item.total),0)} </Labelmoney>
                      },{}
                    ]}
                    data={Ticket?.items}
                    />
                  </div>
                  :""}
                </div>


                <div className={(!enableCambio?"hidden":'w-full h-full')}>
                  {(JSON.stringify(Ticket)!=="{}")?
                  <div className='w-full h-full border border-gray-500 text-xs lg:text-sm overflow-y-auto'>
                    <TableModal
                    searchinput={false}
                    columns={[
                        { header: "Codigo",
                          // eslint-disable-next-line react/display-name
                          code: (item) => (
                          <div className='size-form'>
                            <button 
                            className={('text-blue-600 rounded-full hover:text-sky-500 hover:scale-110 transform mr-1')}
                            hidden={(item?.id?(item.id.substring(0,2)=="SE"?true:false):false)}
                            onClick={e=>{
                              const new_item= {...item,devolucion:true};
                              const funcionExito=(resp)=>{
                                const item_resp = resp.productos[0];
                                //console.log('===>',item_resp);
                                new_item.marca = item_resp.marca;
                                new_item.categoria = item_resp.categoria;

                                let itemsGarantia = [...garantia.items];
                                itemsGarantia.push(new_item);
                                closemodalLoading();
                                addItem(new_item);
                                openmodalItem();
                              };
                              const funcionError =(error)=>{closemodalLoading(); console.log(error);};
                              openmodalLoading();
                              useGetProduct({producto:item.id,funcionExito:funcionExito,funcionError:funcionError});
                            }}>
                              <i className='fas fa-plus-circle bg-white rounded-full'></i>
                            </button>
                            <label className='text-size' >{item?.id||""}</label>
                          </div>)
                        },
                        { header: "Descripcion", accessor: "descripcion"},
                        { header: "Qty", 
                          width:"w-20",
                          // eslint-disable-next-line react/display-name
                          code: (item) => (<InputPrecioPanel
                            width="w-full"
                            min={0}
                            noShowSignal={true}
                            readOnly={true}
                            disabled={true}
                            newValor={moneyToValue(item?.cantidadVendida)}
                            setValor={(e)=>{}}
                          />),
                        },
                        { header: "Precio U",
                          // eslint-disable-next-line react/display-name
                          code: (item) => <Labelmoney twoDecimal={true}>{item.precioVenta}</Labelmoney>,
                        },
                        { header: "Desc %",
                          // eslint-disable-next-line react/display-name
                          code: (item) => <Labelmoney twoDecimal={true}>{item.precioVenta}</Labelmoney>,
                        },
                        {
                          header: "Total",
                        // eslint-disable-next-line react/display-name
                          code: (item) => <Labelmoney twoDecimal={true}>{item.importe}</Labelmoney>,
                        },
                        ]}
                    footer={[
                      {},{},{},{},
                      {
                        width: "w-24",
                        // eslint-disable-next-line react/display-name
                        code:  <Labelmoney twoDecimal={true} className="font-semibold text-center">{tickets.reduce((total,item)=> parseInt(total)+parseInt(item.total),0)} </Labelmoney>
                      },{}
                    ]}
                    data={Ticket?.items}
                    />
                  </div>
                  :""}
                </div>
              </div>
            </div>
          </div>
       
        </Modalgeneric.Body>
        <Modalgeneric.Buttons >
          <button
            type="button"
            className="btn-blue1"
            disabled={(JSON.stringify(Ticket)=="{}"||enableGarantia?true:false)}
            onClick={(e) => {
              const venta = {...Ticket.venta};
              venta.items = Ticket.items;
              SetTicket(venta);
              setRuta("ticketCopia");
            }}
          >
            Imprimir
          </button>
          <button
            type="button"
            className="btn-gray2 mx-1"
            onClick={closemodalgeneric}
          >
            Cerrar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>
        <div className='size-form w-full justify-center group'>
            <button
            className='btn-gray2 w-full'
            onClick={e=>{
                openmodalgeneric();
            }}
            >
              <i className="fas fa-search">
                <p className={windowSize>=1440?"":'hidden'}>Busca Ticket</p>
              </i>
            </button>
            <span className={" tooltiptext lg:hidden"}>
              Buscar Ticket
            </span>
        </div>
      </>
  )
}

const mapStateToProps = (state) => ({
  Ruta: state.Ruta,
  Motivos: state.Catalogos.GARANTIAS,
  itemsVenta: state.itemsVenta,
  Categorias: state.Catalogos.CATEGORIAS.opciones
});

const mapDispatchToProps = (dispatch) => ({
  setRuta: (ruta) => dispatch(setRuta(ruta)),
  SetTicket: (datos) =>
    dispatch({
      type: "SET_TICKET",
      ticket: datos,
    }),
    setItemsVenta: (item) =>
    dispatch({
      type: "SET_ITEMSDATA",
      items: item,
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(BuscaTicket)